import React, { useRef } from "react";
import CropperJS from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from "react-modal";
import styled, { css } from "../../theme/styled-components";
import { defaultShadow } from "../defaultShadow";

const StyledModal = styled(Modal)`
  position: absolute;
  width: 80%;
  top: 10%;
  left: 10%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.plainWhite};
  outline: none;
  ${defaultShadow};
  border-radius: 12px;
  overflow-y: scroll;
`;

const StyledCropper = styled(CropperJS)`
  position: relative;
  height: 500px;
  width: 100%;
  box-sizing: border-box;
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

const BaseButton = css`
  min-width: 100px;
  outline: none;
  border-style: none;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  font-size: 1.2rem;
  font-family: "Roboto";
  font-weight: 500;
  color: ${({ theme }) => theme.colors.plainWhite};
  margin-left: 10px;
  margin-right: 10px;
`;

const CancelButton = styled.button`
  ${BaseButton};
  color: ${({ theme }) => theme.colors.grey};
`;

const ConfirmButton = styled.button`
  ${BaseButton};
  background-color: ${({ theme }) => theme.colors.vibrantBlue};
`;

type ICropperProps = typeof CropperJS["defaultProps"] & {
  isVisible: boolean;
  onConfirmCrop: (cropResult: string) => void;
  onCancel: () => void;
};

export function Cropper({
  src,
  isVisible,
  onConfirmCrop,
  onCancel,
  aspectRatio,
}: ICropperProps) {
  //
  const cropperRef = useRef<HTMLImageElement>(null);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    onConfirmCrop(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <StyledModal isOpen={isVisible}>
      <StyledCropper
        src={src}
        aspectRatio={aspectRatio ? aspectRatio : 16 / 9}
        guides={true}
        ref={cropperRef}
        viewMode={2}
      />
      <Menu>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <ConfirmButton onClick={onCrop}>Crop</ConfirmButton>
      </Menu>
    </StyledModal>
  );
}
