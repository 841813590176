import * as React from "react";
import { useHistory } from "react-router";
import { ActionButton } from "../../../../../components/Buttons/ActionButton";
import { FormikTitleInput } from "../../../../../components/Formik/FormikTitleInput";
import { Header } from "../../../Shared/Header";
import { AiTwotoneEdit } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { BooleanParam, useQueryParam } from "use-query-params";
import { ErrorMessage, useFormikContext } from "formik";
import { Fields } from "../form/schemas";
import _, { includes } from "lodash";
import { Link } from "react-router-dom";
import { originalGameState } from "../GameStore";
import { useRecoilState, useRecoilValue } from "recoil";
import { IoIosCopy, IoIosShare } from "react-icons/io";

export const TitleBar = () => {
  //
  const [isEditing, setIsEditing] = useQueryParam("isEditing", BooleanParam);

  const history = useHistory();

  const formik = useFormikContext<Fields>();
  const errorMessage = [] as any;

  const [ originalGame ] = useRecoilState<any | null>(originalGameState);

  const validate = (error: any, warningField: string, message: string) => {
    if (JSON.stringify(error.questions[0]).includes(warningField)) {
      if (!errorMessage.includes(message)) {
        errorMessage.push(message);
      }
    }
  };

  const onClickActionButton = () => {
    if (isEditing) {
      if (formik.isValid) {
        formik.handleSubmit();
      } else {
        const error = formik.errors;
        _.forEach(error, () => {
          if (error.questions && error.questions[0]) {
            validate(error, "correctAnswer", "Correct answer is undefined...");
            validate(error, "title", "Game title is undefined...");
            validate(error, "false", "False answer is undefined...");
            if (JSON.stringify(error.questions[0]).includes("questionPhoto")) {
              if (JSON.stringify(error.questions[0]).includes("resource")) {
                validate(error, "resource", "Resource URI is undefined...");
                if (JSON.stringify(error.questions[0]).includes("author")) {
                  validate(
                    error,
                    "author",
                    "Question photo author is undefined..."
                  );
                } else {
                  validate(
                    error,
                    "questionPhoto",
                    "Question photo is missing fields..."
                  );
                }
              }
            }
          }
        });
        if (errorMessage.length === 0) {
          errorMessage.push("Title or Cover Photo are missing fields...");
          alert(errorMessage.join("\n"));
        } else {
          alert(errorMessage.join("\n"));
        }
      }
    } else {
      setIsEditing(true);
    }
  };

  return (
    <Header
      onPressBack={() => {
        history.push("/dashboard/cms");
      }}
      showBackButton
    >
      <FormikTitleInput
        name="title"
        noValuePlaceholder="Untitled game"
        placeholder="Enter a title for your game..."
        disabled={!isEditing}
      />
        <ActionButton
        icon={IoIosCopy}
        text="Copy ID"
        onClick={() => {
          navigator.clipboard.writeText(originalGame.info.uid)
        }}
        />
        <ActionButton
        icon={IoIosShare}
        text="Play"
        onClick={() => {
          // @ts-ignore
          const path = window.mlg.path.serialize({ id: originalGame._id });
          window.open("https://play.memorylanegames.com/game/" + path);
        }}
        />
        <ActionButton
          icon={!!isEditing ? TiTick : AiTwotoneEdit}
          text={!!isEditing ? "Save Game" : "Edit Game"}
          onClick={onClickActionButton}
        />
    </Header>
  );
};
