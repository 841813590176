import { useField } from "formik";
import React, { useEffect, useState } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import { OptionType } from "../../../../../../../components/Inputs/CreateableSelect";
import {
  ISelectInputProps,
  SelectInput,
} from "../../../../../../../components/Inputs/SelectInput";
import { useScript } from "../../../../../../../CustomHooks";
import geohash from "ngeohash";
import { Location } from "@memorylanegames/types";
import { BaseLabel } from "../../../../../../../components/Text";
import styled from "../../../../../../../theme/styled-components";

const Container = styled.div`
  width: 100%;
`;

const ClearLink = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.darkBlue};
  cursor: pointer;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
`;

interface ILocationInputProps extends ISelectInputProps {
  name: string;
}

export const LocationInput = (props: ILocationInputProps) => {
  //
  const { name, isDisabled, ...rest } = props;

  const [field, meta, helpers] = useField(name);

  const location = field.value;

  const [locationSuggestions, setLocationSuggestions] = useState<OptionType[]>(
    []
  );

  // Resolve the location placeId to lat and long if there is one
  const resolveLocation = async (
    location: OptionType | null
  ): Promise<Location | undefined> => {
    if (location) {
      const geocoder = new window.google.maps.Geocoder();
      const { lat, long } = await new Promise<{ lat: number; long: number }>(
        (resolve, reject) => {
          geocoder.geocode({ placeId: location.value }, (result) => {
            if (result.length > 0) {
              const loc = result[0].geometry.location;
              resolve({ lat: loc.lat(), long: loc.lng() });
            } else {
              reject();
            }
          });
        }
      );
      return {
        name: location.label,
        placeId: location.value,
        geopoint: {
          type: "Point",
          coordinates: [long, lat],
        },
        geohash: geohash.encode(lat, long),
      };
    } else {
      return undefined;
    }
  };

  const {
    value: locationSearch,
    suggestions: { data: places },
    setValue: setLocation,
  } = usePlacesAutocomplete({
    debounce: 300,
    callbackName: "initMap",
  });

  const isMapsAPILoaded = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GCP_API_KEY}&libraries=places&callback=initMap`
  );

  useEffect(() => {
    if (isMapsAPILoaded && places) {
      setLocationSuggestions(
        places.map((place) => ({
          label: place.description,
          value: place.place_id,
        }))
      );
    }
  }, [places, isMapsAPILoaded]);

  const onReset = () => {
    setLocation("");
    helpers.setValue(null);
  };

  return (
    <Container>
      <SelectInput
        {...field}
        options={locationSuggestions}
        name={name}
        onInputChange={(value) => {
          setLocation(value);
        }}
        onChange={async (o) => {
          const option = o as OptionType;
          const location = await resolveLocation(option);
          helpers.setValue(location);
        }}
        value={
          location
            ? { value: location.placeId, label: location.name }
            : locationSearch
            ? { label: locationSearch, value: "" }
            : null
        }
        onMenuOpen={() => onReset()}
        isDisabled={isDisabled}
        {...rest}
      />
      {location && !isDisabled && (
        <ClearLink onClick={onReset}>Clear</ClearLink>
      )}
    </Container>
  );
};
