import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { IoIosCheckmark } from "react-icons/io";
import styled from "../../../../theme/styled-components";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { ConfirmationButton } from "../../../../components/Buttons/ConfirmationButton";
import { Card } from "../../../../components/Cards/Card";
import { Header } from "../../Shared/Header";
import { Prompt } from "react-router";
import { BooleanParam, NumberParam, useQueryParam } from "use-query-params";
import _ from "lodash";
import { Fields } from "./form/schemas";
import { FormikPhotoInput } from "../../../../components/Formik/FormikPhotoInput";
import { FormikInput } from "../../../../components/Formik/FormikInput";
import { FormikTitleInput } from "../../../../components/Formik/FormikTitleInput";
import { BaseText } from "../../../../components/Text";
import { AnswerAutocompleteButton } from "./AnswerAutocompleteButton";

const Details = styled.div`
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > div {
    margin-bottom: 2rem;
  }
`;

const DeleteText = styled(BaseText)`
  color: red;
  margin-bottom: 25px;
  cursor: pointer;
`;

type InputDetails = {
  label: string;
  placeholder: string;
  id: "false1" | "false2" | "false3";
};

const falseAnswers: InputDetails[] = [
  { label: "False answer", placeholder: "False answer...", id: "false1" },
  { label: "False answer", placeholder: "False answer...", id: "false2" },
  { label: "False answer", placeholder: "False answer...", id: "false3" },
];

export function EditQuestion() {
  //
  // Get some info of the route from hooks
  const [, setIsEditingQuestion] = useQueryParam(
    "isEditingQuestion",
    BooleanParam
  );
  const [currentIndex] = useQueryParam<number>(
    "currentQuestion",
    NumberParam as any
  );

  const { values, setFieldValue } = useFormikContext<Fields>();

  const onSaveQuestion = async () => {
    // Validate the current question
    try {
      // await createValidationSchema(values).validateAt(
      //   `questions[${currentIndex}]`,
      //   values as any
      // );
      setIsEditingQuestion(false);
    } catch (error) {
      alert("Incomplete question - please fill in all fields before saving!");
    }
  };

  const {
    title,
  } = values;

  const {
    correctAnswer: answer,
    false1,
    false2,
    false3,
    title: question,
  } = values.questions[currentIndex];

  useEffect(() => {
    if (true) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, []);

  const onAutocompleteAnswers = (answers: Array<string>) => {
    for (let i = 0; i < answers.length; i++) {
      setFieldValue(`questions.${currentIndex}.false${i + 1}`, answers[i]);
    }
  };

  const onAutocompleteError = (error: Error) => {
    alert(error);
  };

  return (
    <>
      <Prompt
        when={true}
        message={(location, action) => {
          return location.pathname.startsWith("/dashboard/cms/games")
            ? true
            : "Are you sure you want to exit? All progress will be lost.";
        }}
      />
      <Header>
        <FormikTitleInput
          noValuePlaceholder="Untitled question"
          placeholder="Enter a question..."
          name={`questions.${currentIndex}.title`}
        />
        <ActionButton
          icon={IoIosCheckmark}
          text="Save Question"
          onClick={onSaveQuestion}
        />
      </Header>
      <Details>
        <FormikPhotoInput
          label="Question Photo"
          name={`questions.${currentIndex}.questionPhoto`}
        />
        <FormikPhotoInput
          label="Correct answer photo (optional)"
          name={`questions.${currentIndex}.correctAnswerPhoto`}
        />
        <DeleteText
          onClick={() => {
            setFieldValue(`questions.${currentIndex}.correctAnswerPhoto`, null);
          }}
        >
          Delete Correct Answer Photo
        </DeleteText>
        <Card>
          <FormikInput
            label="Correct Answer"
            placeholder="Correct answer..."
            name={`questions.${currentIndex}.correctAnswer`}
          />
          <div style={{ width: '100%', marginTop: '1rem', display: "flex", justifyContent: "center", alignItems: "center"}}>
            <AnswerAutocompleteButton
              answer={answer}
              question={question}
              title={title}
              onAnswers={onAutocompleteAnswers}
              onError={onAutocompleteError}
              difficulty="hard"
            >
              Generate False Answers
            </AnswerAutocompleteButton>
          </div>
        </Card>
        <Card>
          {[false1, false2, false3].map((value, index, arr) => {
            const { id, placeholder, label } = falseAnswers[index];
            return (
              <FormikInput
                id={`false${index + 1}`}
                key={`false${index + 1}`}
                name={`questions.${currentIndex}.false${index + 1}`}
                label={label}
                placeholder={placeholder}
                bottomMargin={index !== arr.length - 1}
              />
            );
          })}
        </Card>
        <ConfirmationButton type="submit" onClick={onSaveQuestion}>
          Save question
        </ConfirmationButton>
      </Details>
    </>
  );
}
