import { QuestionType } from "@memorylanegames/types";
import React, { useMemo, useState } from "react";
import styled, { css } from "../../../../../../theme/styled-components";
import { Draggable } from "react-beautiful-dnd";
import { BsArrowsMove, BsThreeDotsVertical } from "react-icons/bs";
import { Card } from "../../../../../../components/Cards/Card";
import { BaseText } from "../../../../../../components/Text";
// @ts-ignore
import { Textfit } from "react-textfit";
import { ActionButton } from "../../../../../../components/Buttons/ActionButton";
import { v4 as uuidv4 } from "uuid";
import { Fields } from "../../form/schemas";

const Container = styled(Card)`
  flex-shrink: 0;
  width: calc(450px * 1.6);
  height: 450px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  box-sizing: border-box;
`;

const PhotoAspectRatio = styled.div`
  flex: 3;
  padding-left: 56.25%;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
`;

const Photo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: center;
  align-items: center;
`;

const Title = styled(BaseText)`
  font-family: "Raleway";
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
`;

const AnswersRow = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Answer = styled(Textfit)`
  width: 22%;
  height: 100%;
  background-color: ${({ theme, $isCorrectAnswer }) => {
    if ($isCorrectAnswer) {
      return theme.colors.green;
    } else {
      return theme.colors.vibrantBlue;
    }
  }};
  outline: none;
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway";
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.plainWhite};
  font-size: 1.2rem;
  padding: 10px;
  box-sizing: border-box;
`;

const Icon = styled.div<any>`
  position: absolute;
  ${({ $isLeft }) => {
    if ($isLeft) {
      return { left: "15px" };
    } else {
      return { right: "15px" };
    }
  }};
  top: 15px;
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-right: 15px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 15px;
  pointer-events: auto;
  cursor: pointer;
  overflow: visible;
`;

interface IMenuProps extends React.HTMLProps<HTMLDivElement> {
  isVisible: boolean;
}

const Menu = styled(Card)<IMenuProps>`
  position: absolute;
  top: 30px;
  right: 15px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: auto;
  cursor: default;
  ${(props) => {
    if (!props.isVisible) {
      return css`
        display: none;
      `;
    }
  }}
`;

const DeleteButton = styled.button`
  position: relative;
  outline: none;
  border-style: none;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.errorRed};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: "Roboto";
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.plainWhite};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

interface IQuestionCardProps {
  question: Fields["questions"][0];
  index: number;
  isEditing: boolean;
  onDeleteQuestion: () => void;
}

export function QuestionCard(props: IQuestionCardProps) {
  //
  const { question, index, isEditing, onDeleteQuestion } = props;

  const [isMenuVisible, setMenuVisible] = useState(false);

  const cardId = useMemo(() => {
    return uuidv4();
  }, []);

  return (
    <Draggable draggableId={cardId} index={index} isDragDisabled={!isEditing}>
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <InnerWrapper>
            <PhotoAspectRatio>
              <Photo
                style={{
                  backgroundImage: `url(${question.questionPhoto.uri})`,
                  backgroundSize: "cover",
                }}
              />
            </PhotoAspectRatio>
            <TitleContainer>
              <Title as={Textfit} max={22} mode="multi">
                {question.title}
              </Title>
            </TitleContainer>
            <AnswersRow>
              {[
                question.correctAnswer,
                question.false1,
                question.false2,
                question.false3,
              ].map((answer) => (
                <Answer
                  max={22}
                  mode="multi"
                  key={answer}
                  disabled
                  $isCorrectAnswer={answer === question.correctAnswer}
                >
                  {answer}
                </Answer>
              ))}
            </AnswersRow>
          </InnerWrapper>
          {isEditing ? (
            <>
              <Icon as={BsArrowsMove} $isLeft />
              <IconContainer
                onClick={() => {
                  setMenuVisible(!isMenuVisible);
                }}
              >
                <Icon as={BsThreeDotsVertical} $isLeft={false} />
                <Menu isVisible={isMenuVisible}>
                  <DeleteButton onClick={onDeleteQuestion}>Delete</DeleteButton>
                </Menu>
              </IconContainer>
            </>
          ) : null}
        </Container>
      )}
    </Draggable>
  );
}
