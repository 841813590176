import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "../../../../../../theme/styled-components";
import { Pagination } from "./Pagination";
import { QuestionCard } from "./QuestionCard";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import composeRefs from "@seznam/compose-react-refs";
import { ActionButton } from "./ActionButton";
import _ from "lodash";
import { useFormikContext } from "formik";
import { Fields } from "../../form/schemas";
import { BooleanParam, NumberParam, useQueryParam } from "use-query-params";
import { blankQuestion } from "../../form/blanks";
import { v4 as uuidv4 } from "uuid";

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: space-between;
  margin-bottom: 2rem;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.nearlyWhite};
  overflow: auto;
  margin-bottom: 2rem;
`;

const Scroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  margin-bottom: 1rem;
  overflow-y: visible;
`;

const ScrollBuffer = styled.div`
  flex-shrink: 0;
  height: 10px;
  width: calc((100% - ((450px * 1.6) + 80px)) / 2);
  overflow: auto;
`;

export function Preview() {
  //
  const scrollRef = useRef<HTMLDivElement>(null);

  // Only renders if the current index is not (null or undefined)
  const [currentIndex, setCurrentIndex] = useQueryParam<number>(
    "currentQuestion",
    NumberParam as any
  );
  const [isEditing] = useQueryParam("isEditing", BooleanParam);
  const [, setIsEditingQuestion] = useQueryParam(
    "isEditingQuestion",
    BooleanParam
  );

  const formik = useFormikContext<Fields>();

  const { values, setFieldValue } = formik;

  const { questions } = values;

  const onReorder = (start: number, end: number) => {
    // Pull the question out of the array and re-insert it at the new position
    const reorderedQuestions = _.cloneDeep(questions);
    const [removed] = reorderedQuestions.splice(start, 1);
    reorderedQuestions.splice(end, 0, removed);
    setFieldValue("questions", reorderedQuestions);
  };

  const onAddQuestion = () => {
    // Add a blank question in and jump to the next index for it
    const questionsCopy = _.cloneDeep(questions);
    questionsCopy.splice(currentIndex + 1, 0, blankQuestion);
    setCurrentIndex(currentIndex + 1);
    setFieldValue("questions", questionsCopy);
  };

  const onDeleteQuestion = (index: number) => {
    const questionsCopy = _.cloneDeep(questions);
    // Check there is at least two questions in the game
    if (questionsCopy.length >= 2) {
      questionsCopy.splice(currentIndex, 1);
      // If we are on the last index jump back one to avoid being out of bounds
      if (questions.length - 1 === index) {
        setCurrentIndex(currentIndex - 1);
      }
      setFieldValue("questions", questionsCopy);
    } else {
      alert(
        "Cannot delete question: Your game must contain at least one question"
      );
    }
  };

  useEffect(() => {
    if (scrollRef.current) {
      // Get the card width including the 80px total horizontal margin on it
      const cardWidth = scrollRef.current.children[1].clientWidth + 80;
      // Calculate the offset to the new card and scroll there
      const scrollOffset = currentIndex * cardWidth;
      scrollRef.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
    }
  }, [scrollRef, currentIndex, questions]);

  const onDragEnd = (result: DropResult) => {
    // IF there is no destination
    if (!result.destination) {
      return;
    }
    // if the destination is the same as the source
    if (result.destination.index === result.source.index) {
      return;
    }
    // Else the destination !== source so reorder the item
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    onReorder(startIndex, endIndex);
  };

  const updateCurrentIndex = useCallback(
    _.debounce(
      (scrollPosition) => {
        if (scrollRef.current) {
          // Check if the index changed as a result of the scroll
          const cardWidth = scrollRef.current.children[1].clientWidth + 80;
          setCurrentIndex(Math.round(scrollPosition / cardWidth));
        }
      },
      500,
      { trailing: true }
    ),
    []
  );

  const previewQuestions = useMemo(() => {
    return questions.map((question) => ({ id: uuidv4(), question }));
  }, [questions]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided) => (
            <Scroll
              ref={composeRefs(provided.innerRef, scrollRef) as any}
              onScroll={({ nativeEvent: { target } }) =>
                updateCurrentIndex((target as HTMLDivElement).scrollLeft)
              }
              {...provided.droppableProps}
            >
              <ScrollBuffer />
              {previewQuestions.map(({ question, id }, index) => (
                <QuestionCard
                  key={id}
                  question={question}
                  index={index}
                  isEditing={!!isEditing}
                  onDeleteQuestion={() => onDeleteQuestion(index)}
                ></QuestionCard>
              ))}
              {provided.placeholder}
              <ScrollBuffer />
            </Scroll>
          )}
        </Droppable>
      </DragDropContext>
      {!!isEditing ? (
        <ButtonContainer>
          <ActionButton text="Add Question" onClick={onAddQuestion} />
          <ActionButton
            text="Edit Question"
            onClick={() => setIsEditingQuestion(true)}
          />
        </ButtonContainer>
      ) : null}
      <Pagination
        currentIndex={currentIndex}
        maxIndex={questions.length - 1}
        onChangeIndex={(index) => setCurrentIndex(index)}
      />
    </Container>
  );
}
