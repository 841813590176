import { Fields } from "./schemas";

export const blankQuestion: Fields["questions"][0] = {
  title: "",
  questionPhoto: {},
  correctAnswerPhoto: {},
  correctAnswer: "",
  false1: "",
  false2: "",
  false3: "",
};

export const blankGame: Fields = {
  title: "",
  keywords: [],
  coverPhoto: {},
  language: "en",
  status: "pending",
  paid: "free",
  notes: "",
  location: null,
  questions: [blankQuestion],
};
