import * as React from "react";
import { keyframes } from "styled-components";
import { BaseText } from "../../../../../components/Text";
import styled from "../../../../../theme/styled-components";
import Modal from "react-modal";
import { uploadingModalOpenState } from "../GameStore";
import { useRecoilValue } from "recoil";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const modalTitleAnimation = keyframes`
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    opacity: 0.3;
  }
`;

const Title = styled(BaseText)`
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  margin: 30px;
  animation: ${modalTitleAnimation} 1s linear infinite;
`;

export const UploadingModal = () => {
  const open = useRecoilValue(uploadingModalOpenState);

  return (
    <Modal
      isOpen={open}
      contentLabel="Upload Modal"
      style={modalStyles}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Title>Uploading game...</Title>
    </Modal>
  );
};
