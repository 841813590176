import React from "react";
import { useHistory } from "react-router";
import styled from "../../../theme/styled-components";
import elephant from "../../../assets/elephant.svg";
import { BaseTitle } from "../../../components/Text";
import { NavButton } from "./NavButton";
import { IoMdHome, IoMdPhotos } from "react-icons/io";
import { MdPhoto } from "react-icons/md";
import { BsFillBarChartFill, BsPersonFill } from "react-icons/bs";
import { Profile } from "./Profile";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../../GlobalStore";
import { Roles } from "@memorylanegames/types";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  width: 250px;
  background-color: ${({ theme }) => theme.colors.plainWhite};
  border-width: 0;
  border-right-width: 2px;
  border-style: solid;
  border-right-color: ${({ theme }) => theme.colors.offWhite};
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 2rem;
`;

const Elephant = styled.div`
  height: 100%;
  flex: 1;
  background: url(${elephant}) no-repeat center;
  background-size: 100% 100%;
  margin-right: 1rem;
`;

const Title = styled(BaseTitle)`
  flex: 4;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
`;

const adminRoles: Roles[] = ["admin", "super-admin"];

export function Sidebar() {
  //
  const history = useHistory();

  const [currentUser] = useRecoilState(currentUserState);

  return (
    <Container>
      <Header onClick={() => history.push("/")}>
        <Elephant />
        <Title>
          Memory Lane
          <br />
          Games
        </Title>
      </Header>
      <NavButton label="Dashboard" icon={IoMdHome} />
      <NavButton label="CMS" icon={MdPhoto} route="/cms" />
      {currentUser && adminRoles.includes(currentUser?.details.role) ? (
        <>
          <NavButton
            label="Analytics"
            icon={BsFillBarChartFill}
            route="/analytics"
          />
          <NavButton label="Admin" icon={BsPersonFill} route="/admin" />
        </>
      ) : null}
      <Profile />
    </Container>
  );
}
