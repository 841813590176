import { CMSUser, GameType } from "@memorylanegames/types";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { hasNextPageOfUsersState, loadedUsersState } from "../../GlobalStore";
import { getAuthHeaders } from "../auth";
import _ from "lodash";
import { FiltersInterface } from "../../screens/Dashboard/Shared/SearchBar/SearchBar";
import { serverUrl } from "../constants";

interface GetPaginationParams {
  page: number;
  sortBy: string;
  filters: FiltersInterface;
  searchTerm: string;
}

export const getPaginatedUsers = async ({
  page,
  sortBy,
  filters,
  searchTerm,
}: GetPaginationParams) => {
  const authHeaders = await getAuthHeaders();
  // Convert the filters interface to keys of filter types with an array of values
  // to match by e.g. --> { status: ['approved', 'pending'] }
  const formattedFilters = _.reduce(
    filters,
    (obj, filters, filterType) => {
      return {
        ...obj,
        [filterType]: _.flatMap(filters, (value, key) => (value ? key : [])),
      };
    },
    {}
  );
  const res = await fetch(
    `${serverUrl}/cmsUsers/getPaginatedUsers?` +
      new URLSearchParams({
        page: page.toString(),
        sortBy: sortBy.toString(),
        filters: JSON.stringify(formattedFilters),
        searchTerm,
      }),
    {
      headers: {
        ...authHeaders,
      },
    }
  );
  if (!res.ok) {
    throw new Error(
      `Couldn't fetch general games, error code: ${res.status.toString()}`
    );
  }
  const body = await res.json();
  const users: CMSUser[] = body.users;
  const hasNextPage: boolean = body.hasNextPage;
  return { users, hasNextPage };
};

interface LoadPaginationParams {
  sortBy: string;
  filters: FiltersInterface;
  searchTerm: string;
  reset?: boolean;
}

export const useLoadPaginatedUsers = () => {
  const [loadedUsers, setLoadedUsers] = useRecoilState(loadedUsersState);
  const [, setHasNextPage] = useRecoilState(hasNextPageOfUsersState);
  const pageIndex = Math.round(loadedUsers.length / 6) + 1;
  const load = useCallback(
    async ({ reset, ...rest }: LoadPaginationParams) => {
      const { users, hasNextPage } = await getPaginatedUsers({
        page: reset ? 1 : pageIndex,
        ...rest,
      });
      setLoadedUsers(
        _.uniqBy([...(reset ? [] : loadedUsers), ...users], "details.uid")
      );
      setHasNextPage(hasNextPage);
    },
    [loadedUsers, setLoadedUsers, pageIndex, setHasNextPage]
  );
  return load;
};
