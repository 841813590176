import { GameType } from "@memorylanegames/types";
import { useCallback } from "react";
import { getAuthHeaders } from "../auth";
import pica from "pica";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase";
import _ from "lodash";
import { serverUrl } from "../constants";
import { uriToBlob } from "../utils";

export const uploadGame = async (gameData: GameType) => {
  const authHeaders = await getAuthHeaders();
  const game = _.cloneDeep(gameData);
  const { info, coverPhoto, questions } = game;
  // Get a UUID for the game - if it already has one use the same reference
  const gameId = info.uid ? info.uid : uuidv4();
  // Start the process of uploading - making sure we have a valid user ID
  // to upload as
  const userId = firebase.auth().currentUser?.uid;
  if (userId) {
    // Populate all of the additional metadata about the game
    info.authorId = info.authorId !== "" ? info.authorId : userId;
    info.uid = gameId;
    info.dateCreated = Date.now();
    if (info.status !== "pending") {
      info.approverId = userId;
    }
    // Create the form data to send to the server
    const formData = new FormData();
    // Go through each image and get its url or convert it to a blob
    if (typeof coverPhoto.uri === "string") {
      if (!coverPhoto.uri.startsWith("http")) {
        const coverPhotoRef = uuidv4();
        const imageBlob = await uriToBlob(coverPhoto.uri);
        formData.append(coverPhotoRef, imageBlob, coverPhotoRef + ".jpg");
        coverPhoto.uri = coverPhotoRef;
      }
    }
    // Do the same for the question photos
    for (const question of questions) {
      const { questionPhoto, correctAnswerPhoto } = question;
      if (typeof questionPhoto.uri === "string") {
        if (!questionPhoto.uri.startsWith("http")) {
          const questionPhotoRef = uuidv4();
          const imageBlob = await uriToBlob(questionPhoto.uri);
          formData.append(
            questionPhotoRef,
            imageBlob,
            questionPhotoRef + ".jpg"
          );
          questionPhoto.uri = questionPhotoRef;
        }
      }
      // Check the correct answer photo isn't null
      if (correctAnswerPhoto && typeof correctAnswerPhoto.uri === "string") {
        if (!correctAnswerPhoto.uri.startsWith("http")) {
          const correctAnswerPhotoRef = uuidv4();
          const imageBlob = await uriToBlob(correctAnswerPhoto.uri);
          formData.append(
            correctAnswerPhotoRef,
            imageBlob,
            correctAnswerPhotoRef + ".jpg"
          );
          correctAnswerPhoto.uri = correctAnswerPhotoRef;
        }
      }
    }
    // Finally add in the game data as JSON
    formData.append("gameData", JSON.stringify(game));
    const res = await fetch(`${serverUrl}/coreGames`, {
      method: "POST",
      headers: {
        ...authHeaders,
      },
      body: formData,
    }).catch((e) => {
      throw new Error(
        "Failed to connect to server - please check your connection and try again!"
      );
    });
    if (!res.ok) {
      throw new Error("Could not upload game! " + res.statusText);
    }
  } else {
    throw new Error("You are logged out! Sign in and try again!");
  }
};
