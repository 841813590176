import React from "react";
import { Redirect, Route } from "react-router-dom";
import firebase from "firebase";
import { useRecoilValue } from "recoil";
import { hasCheckedAuthState } from "../GlobalStore";

type IPrivateRouteProps = any;

export const PrivateRoute = ({
  component: Component,
  ...rest
}: IPrivateRouteProps) => {
  //
  const hasCheckedAuth = useRecoilValue(hasCheckedAuthState);
  const user = firebase.auth().currentUser;

  return (
    <Route
      {...rest}
      render={(props) =>
        user && hasCheckedAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
