import { CMSUser } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";

export const getUserById = async (userId: string) => {
  const authHeaders = await getAuthHeaders();
  const res = await fetch(`${serverUrl}/cmsUsers/getUserById/${userId}`, {
    headers: {
      ...authHeaders,
    },
  });
  if (!res.ok) {
    return undefined;
  }
  const game: CMSUser = await res.json();
  return game;
};
