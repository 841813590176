import React from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import styled, { css } from "../../theme/styled-components";
import { BaseText } from "../Text";
import { animated } from "react-spring";
import TextareaAutosize from "react-autosize-textarea";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
`;

interface IContainerProps extends React.HTMLProps<HTMLDivElement> {
  isEditing: boolean;
  isDisabled: boolean;
}

const InputContainer = styled.div<IContainerProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    !props.isEditing &&
    !props.isDisabled &&
    css`
      &:hover {
        opacity: 0.7;
      }
    `};
  transition: all 0.5s;
`;

const Input = styled(animated(TextareaAutosize))`
  width: 100%;
  font-family: "Raleway";
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  border: none;
  outline: none;
  min-width: 800px;
  border-width: 2px;
  border-style: solid;
  border-radius: 6px;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s;
  padding: 10px;
  padding-left: 15px;
  resize: none;
  overflow: auto;
`;

const Placeholder = styled(animated.div)`
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  top: 12px;
`;

const PlaceholderText = styled(BaseText)`
  font-family: "Raleway";
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.grey};
`;

const Icon = styled.div`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.colors.grey};
  margin-left: 20px;
`;

export interface ITitleInputProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  noValuePlaceholder?: string;
  isEditing?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  inputStyles?: React.CSSProperties;
  placeholderStyles?: React.CSSProperties;
}

export function TitleInput(props: ITitleInputProps) {
  //
  const {
    className,
    noValuePlaceholder,
    value,
    disabled,
    isEditing,
    onMouseEnter,
    onMouseLeave,
    inputStyles,
    placeholderStyles,
    ...rest
  } = props;

  return (
    <Container>
      <InputContainer
        className={className}
        isEditing={!!isEditing}
        isDisabled={Boolean(disabled)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Placeholder style={placeholderStyles}>
          <PlaceholderText>{noValuePlaceholder}</PlaceholderText>
          <Icon as={AiTwotoneEdit} />
        </Placeholder>
        <Input
          {...rest}
          style={inputStyles}
          value={value}
          disabled={disabled}
        />
      </InputContainer>
    </Container>
  );
}
