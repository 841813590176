import React from "react";
import styled from "../../../theme/styled-components";
import { PageTitle } from "../../../components/Text";
import { Header } from "../Shared/Header";

export function Analytics() {
  return (
    <>
      <Header>
        <PageTitle>Analytics</PageTitle>
      </Header>
    </>
  );
}
