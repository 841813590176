import React from "react";
import { defaultButtonCss } from "../../../components/Buttons/Button";
import { PageTitle } from "../../../components/Text";
import styled from "../../../theme/styled-components";
import { Header } from "../Shared/Header";
import firebase from "firebase";
import { useHistory } from "react-router";

const LogoutButton = styled.button`
  ${defaultButtonCss};
  font-size: 1.2rem;
  min-width: 100px;
`;

export const Profile = () => {
  //
  const history = useHistory();

  const onLogout = () => {
    firebase.auth().signOut();
    history.push("/");
  };

  return (
    <>
      <Header>
        <PageTitle>My profile</PageTitle>
      </Header>
      <LogoutButton onClick={onLogout}>Logout</LogoutButton>
    </>
  );
};
