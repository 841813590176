import React from "react";
import { IconType } from "react-icons/lib";
import styled from "../../theme/styled-components";
import { BaseLabel } from "../Text";
import { defaultButtonCss } from "./Button";

const Container = styled.div`
  ${defaultButtonCss};
  height: 50px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  padding-left: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 15px;
`;

const Icon = styled.div`
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.plainWhite};
  margin-right: 15px;
`;

const Text = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.plainWhite};
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;

interface IActionButtonProps extends React.HTMLProps<HTMLDivElement> {
  icon: IconType;
  text: string;
}

export function ActionButton(props: IActionButtonProps) {
  const { icon, text, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Icon as={icon} />
      <Text>{text}</Text>
    </Container>
  );
}
