import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { deleteGame } from "../../../../../../backend/mutations/deleteGame";
import { defaultButtonCss } from "../../../../../../components/Buttons/Button";
import { TitleCard } from "../../../../../../components/Cards/TitleCard";
import { defaultShadow } from "../../../../../../components/defaultShadow";
import { BaseText } from "../../../../../../components/Text";
import styled from "../../../../../../theme/styled-components";
import Modal from "react-modal";
import { BooleanParam, useQueryParam } from "use-query-params";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  align-self: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.plainWhite};
  outline: none;
  ${defaultShadow};
  border-radius: 12px;
  width: 400px;
  padding: 2rem;
`;

const CardText = styled(BaseText)`
  font-size: 1.2rem;
`;

const ConfirmTitle = styled(BaseText)`
  text-align: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  margin-bottom: 2rem;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Button = styled.button`
  ${defaultButtonCss};
  min-width: 100px;
  font-size: 1rem;
`;

const CancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.nearlyBlack};
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const DeleteButton = styled(Button)`
  background-color: ${({ theme }) => "red"};
`;

export const DeleteGame = () => {
  //
  const history = useHistory();

  const { gameId } = useParams<any>();
  const [isEditing] = useQueryParam("isEditing", BooleanParam);

  const [isDeleting, setDeleting] = useState(false);

  const onDeleteGame = async () => {
    try {
      await deleteGame(gameId);
      history.push(`/dashboard/cms/`);
    } catch (error) {
      alert(error);
    }
  };

  if (!gameId || !isEditing) {
    return null;
  }

  return (
    <TitleCard title="Danger Zone">
      <Container>
        <CardText>Permanantly delete game</CardText>
        <DeleteButton onClick={() => setDeleting(true)}>Delete</DeleteButton>
      </Container>
      <StyledModal
        isOpen={isDeleting}
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <ConfirmTitle>
          <b>
            <p style={{ color: "red", margin: 0, textDecoration: "underline" }}>
              Warning!
            </p>
            <br />
            This will permanantly delete this game - this action cannot be
            undone.
            <br />
          </b>
        </ConfirmTitle>
        <ButtonRow>
          <CancelButton onClick={() => setDeleting(false)}>Cancel</CancelButton>
          <DeleteButton onClick={onDeleteGame}>Delete</DeleteButton>
        </ButtonRow>
      </StyledModal>
    </TitleCard>
  );
};
