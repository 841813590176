import { GameType } from "@memorylanegames/types";
import { atom } from "recoil";

export const uploadingModalOpenState = atom<boolean>({
  key: "uploadingModalOpenState",
  default: false,
});

export const originalGameState = atom<GameType | null>({
  key: "originalGameState",
  default: null,
});
