import { CMSUser } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";
import { uriToBlob } from "../utils";

export const updateUser = async (
  details: Omit<CMSUser["details"], "hasAcceptedTermsAndConditions">
) => {
  const authHeaders = await getAuthHeaders();
  const formData = new FormData();
  const { profilePicture, ...rest } = details;
  // @ts-ignore
  Object.keys(rest).forEach((key) => formData.append(key, rest[key]));
  if (profilePicture.startsWith("http")) {
    formData.append("profilePicture", profilePicture);
  } else {
    const profilePictureBlob = await uriToBlob(profilePicture);
    formData.append("profilePicture", "");
    formData.append("newProfilePicture", profilePictureBlob);
  }
  const res = await fetch(`${serverUrl}/cmsUsers`, {
    method: "PUT",
    headers: {
      ...authHeaders,
    },
    body: formData,
  }).catch((e) => {
    throw new Error(
      "Failed to connect to server - please check your connection and try again!"
    );
  });
  if (!res.ok) {
    throw new Error(
      "Failed to update user! " + res.status + " " + res.statusText
    );
  }
};
