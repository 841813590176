import React from "react";
import styled from "../../../../theme/styled-components";
import { Filter, IFilterProps } from "./Filter";
import { ISearchProps, Search } from "./Search";
import { ISortByProps, SortBy } from "./SortBy";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  box-sizing: border-box;
`;

const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export interface FiltersInterface {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface SortByInterface {
  id: string;
  label: string;
  order: "asc" | "desc";
}

type ISearchBarProps = ISortByProps & IFilterProps & ISearchProps;

export function SearchBar(props: ISearchBarProps) {
  const {
    filters,
    onUpdateFilters,
    sortBys,
    sortBy,
    onUpdateSortBy,
    searchTerm,
    onUpdateSearchTerm,
    onEnter,
  } = props;
  const filterProps = { filters, onUpdateFilters };
  const sortProps = { sortBys, sortBy, onUpdateSortBy };
  const searchProps = { searchTerm, onUpdateSearchTerm, onEnter };
  return (
    <Container>
      <FilterOptions>
        <Filter {...filterProps} />
        <SortBy {...sortProps} />
      </FilterOptions>
      <Search {...searchProps} />
    </Container>
  );
}
