import { GameType } from "@memorylanegames/types";
import { uploadGame } from "../../../../../backend/mutations/uploadGame";
import { conditionalSchemas, Fields, photoValidation } from "./schemas";
import _ from "lodash";

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : NestedPartial<T[K]>;
};

export const validateAndUpload = async (
  values: Fields,
  originalGame: GameType | null
) => {
  //
  const {
    title,
    questions,
    coverPhoto,
    keywords,
    language,
    location,
    status,
    paid,
    notes,
  } = values;
  // Format the questions
  const formattedQuestions = questions
    ? questions.map((q) => ({
        title: q.title,
        questionPhoto: q.questionPhoto,
        correctAnswerPhoto: q.correctAnswerPhoto,
        correctAnswer: q.correctAnswer,
        incorrectAnswers: [q.false1, q.false2, q.false3],
      }))
    : [];
  // Format the info
  const formattedInfo = {
    language,
    location,
    status,
    paid: paid === "paid" ? true : false,
    notes,
  };
  const updatedGame = (): GameType => {
    if (originalGame) {
      return {
        ...originalGame,
        title,
        questions: formattedQuestions,
        coverPhoto,
        keywords,
        info: {
          ...originalGame.info,
          ...formattedInfo,
        },
      };
    } else {
      const game: GameType = {
        title,
        questions: formattedQuestions,
        coverPhoto,
        keywords,
        info: {
          numberOfPlays: 0,
          likes: 0,
          dislikes: 0,
          authorId: "",
          approverId: null,
          dateCreated: 0,
          uid: "",
          location: null,
          ...formattedInfo,
        },
      };
      return game;
    }
  };
  await uploadGame(updatedGame());
};
