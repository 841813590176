import { GameType } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";

export const deleteGame = async (gameId: string) => {
  const authHeaders = await getAuthHeaders();
  const res = await fetch(`${serverUrl}/coreGames`, {
    method: "DELETE",
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gameId,
    }),
  }).catch((e) => {
    throw new Error(
      "Failed to connect to server - please check your connection and try again!"
    );
  });
  if (!res.ok) {
    throw new Error("Could not delete game! " + res.statusText);
  }
};
