import { GameType } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";

export const getGameById = async (gameId: string) => {
  const authHeaders = await getAuthHeaders();
  const res = await fetch(`${serverUrl}/coreGames/getGameById/${gameId}`, {
    headers: {
      ...authHeaders,
    },
  });
  if (!res.ok) {
    return undefined;
  }
  const game: GameType = await res.json();
  return game;
};
