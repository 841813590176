import React from "react";
import styled, { css } from "../../theme/styled-components";
import { BaseLabel, BaseText } from "../Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Label = styled(BaseLabel)``;

export const defaultInputCSS = css<any>`
  min-height: 11.5rem;
  width: 100%;
  border-radius: 12px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.offWhite};
  outline: none;
  padding: 1rem;
  box-sizing: border-box;
  font-family: "Roboto";
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  resize: none;
`;

const StyledInput = styled.textarea`
  ${defaultInputCSS}
`;

export interface IMultilineInputProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  label: string;
  bottomMargin?: boolean;
}

export function MultilineInput(props: IMultilineInputProps) {
  const { label, className, bottomMargin, ...rest } = props;
  return (
    <Container
      className={className}
      style={{ marginBottom: bottomMargin ? "2rem" : 0 }}
    >
      <Label>{label}</Label>
      <StyledInput {...rest} />
    </Container>
  );
}
