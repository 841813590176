import { css } from "../../theme/styled-components";

export const defaultButtonCss = css`
  background-color: ${({ theme }) => theme.colors.vibrantBlue};
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.plainWhite};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 1.5rem;
  outline: none;
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
