import { useField, useFormikContext } from "formik";
import React from "react";
import styled from "../../theme/styled-components";
import { IInputProps, Input } from "../Inputs/Input";
import { BaseText } from "../Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

interface IStyledInputProps extends IInputProps {
  hasError: boolean;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  #input {
    border-color: ${({ theme, hasError }) => hasError && theme.colors.errorRed};
  }
`;

export const Error = styled(BaseText)`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.5rem;
`;

interface IFormikInputProps extends IInputProps {
  name: string;
}

export function FormikInput(props: IFormikInputProps) {
  //
  const { className, bottomMargin, name, ...inputProps } = props;

  // https://formik.org/docs/tutorial#leveraging-react-context
  const [field, meta] = useField(name);

  const { isSubmitting } = useFormikContext();

  const error = meta.touched && meta.error;

  return (
    <Container
      className={className}
      style={{ marginBottom: bottomMargin ? "2rem" : 0 }}
    >
      <StyledInput
        {...field}
        {...inputProps}
        name={name}
        hasError={!!error}
        disabled={isSubmitting}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}
