import { GameType } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";

export const deleteUser = async (userId: string) => {
  const authHeaders = await getAuthHeaders();
  const res = await fetch(`${serverUrl}/cmsUsers`, {
    method: "DELETE",
    headers: {
      ...authHeaders,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
    }),
  }).catch((e) => {
    throw new Error(
      "Failed to connect to server - please check your connection and try again!"
    );
  });
  if (!res.ok) {
    throw new Error("Could not delete user! " + res.statusText);
  }
};
