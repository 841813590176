import React from "react";
import { useField } from "formik";
import { MultilineInput, IMultilineInputProps } from "../Inputs/MultilineInput";

interface IFormikMultlineInputProps extends IMultilineInputProps {
  name: string;
}

export const FormikMultilineInput = (props: IFormikMultlineInputProps) => {
  //
  const { name, ...rest } = props;

  const [ field ] = useField(name);

  return <MultilineInput {...field} {...rest} />;
};
