import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { EditUser } from "./EditUser/EditUser";
import { UserOverview } from "./UserOverview/UserOverview";
import { UserSearch } from "./UserSearch/UserSearch";

export function Admin() {
  //
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <UserSearch />
      </Route>
      <Route path={`${match.path}/users/:id`}>
        <UserOverview />
      </Route>
      <Route path={`${match.path}/edit-user`}>
        <EditUser />
      </Route>
    </Switch>
  );
}
