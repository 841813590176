import { CMSUser } from "@memorylanegames/types";
import { getAuthHeaders } from "../auth";
import { serverUrl } from "../constants";
import { uriToBlob } from "../utils";

export const iniviteNewUser = async (
  details: Omit<CMSUser["details"], "hasAcceptedTermsAndConditions" | "uid">
) => {
  const authHeaders = await getAuthHeaders();
  const formData = new FormData();
  const { profilePicture, ...rest } = details;
  // @ts-ignore
  Object.keys(rest).forEach((key) => formData.append(key, rest[key]));
  const profilePictureBlob = await uriToBlob(profilePicture);
  formData.append("profilePicture", profilePictureBlob);
  const res = await fetch(`${serverUrl}/cmsUsers`, {
    method: "POST",
    headers: {
      ...authHeaders,
    },
    body: formData,
  }).catch((e) => {
    throw new Error(
      "Failed to connect to server - please check your connection and try again!"
    );
  });
  if (!res.ok) {
    throw new Error(
      "Failed to invite user! " + res.status + " " + res.statusText
    );
  }
};
