import React from "react";
import { Card } from "./Card";
import { IItemCardProps } from "./ItemCard";
import { Details, Title } from "./Card";

type ITitleCardProps = Omit<IItemCardProps, "label" | "photo">;

export function TitleCard({
  title,
  className,
  children,
  onClick,
}: ITitleCardProps) {
  return (
    <Card className={className} onClick={onClick}>
      <Details>
        <Title>{title}</Title>
      </Details>
      {children}
    </Card>
  );
}
