import React, { useCallback, useState } from "react";
import { MdAddAPhoto, MdError } from "react-icons/md";
import styled, { css } from "../../theme/styled-components";
import { CardPhoto } from "../Cards/Card";
import { TitleCard } from "../Cards/TitleCard";
import { BaseText } from "../Text";
import { useDropzone } from "react-dropzone";
import { Photo } from "@memorylanegames/types";
import { Cropper } from "./Cropper";
import { Copyright } from "./Copyright";
import { LicenseType } from "@memorylanegames/types";

const PlaceholderContainer = styled.div`
  min-height: 200px;
  width: 100%;
  border-style: solid;
  border-width: 2px;
  border-radius: 12px;
  border-color: ${({ theme }) => theme.colors.offWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IDropzoneProps extends React.HTMLProps<HTMLDivElement> {
  isDragActive: boolean;
  displayOnly: boolean;
}

const Dropzone = styled.div<IDropzoneProps>`
  outline: none;
  display: flex;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  ${({ isDragActive, theme }) => {
    if (isDragActive) {
      return {
        opacity: 0.7,
      };
    }
  }}
  ${({ displayOnly }) => {
    if (!displayOnly) {
      return css`
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      `;
    }
  }}
  transition: all 0.3s;
`;

const Icon = styled.div`
  height: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 20px;
`;

const Prompt = styled(BaseText)`
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const ErrorText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 1rem;
`;

export type Errors = {
  [key: string]: string | boolean | undefined;
};

export type Touches = {
  [key: string]: boolean | undefined;
};

export interface IPhotoInputProps extends React.HTMLProps<HTMLDivElement> {
  photo?: Photo;
  errors?: Errors;
  touches?: Touches;
  label: string;
  onUpdatePhoto?: (photo: Photo) => void;
  copyrightDisabled?: boolean;
  displayOnly?: boolean;
  onTouchedField?: (key: string) => void;
  cropAspectRatio?: number;
}

export function PhotoInput(props: IPhotoInputProps) {
  //
  const {
    photo,
    label,
    onUpdatePhoto,
    className,
    displayOnly,
    errors,
    touches,
    onTouchedField,
    copyrightDisabled,
    cropAspectRatio,
  } = props;

  const onTouchPhoto = useCallback(
    () => onTouchedField && onTouchedField("uri"),
    [ onTouchedField ]
  );

  const [isCropperOpen, setCropperOpen] = useState(false);

  const [editablePhoto, setEditablePhoto] = useState<string>();

  const onDrop = useCallback(
    (acceptedFiles) => {
      onTouchPhoto();
      // Do something with the files
      const reader = new FileReader();

      reader.addEventListener("load", (event) => {
        if (event.target) {
          setEditablePhoto(event.target.result as string);
          setCropperOpen(true);
        }
      });

      if (acceptedFiles[0]) {
        reader.readAsDataURL(acceptedFiles[0]);
      }
    },
    [onTouchPhoto, setEditablePhoto]
  );

  //enables the dropzone to listen for a dropped file.
  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  const wikiMediaIdentifierFromURL = (url: string): string => {
    const match = url.match(/File:[^$/]+/);

    if (match) {
      return decodeURIComponent(match[0]);
    }

    if (url.includes("upload.wikimedia.org")) {
      const split = url.split("/");

      if (split.length > 1) {
        const last = split.pop()!;
        const secondToLast = split.pop()!;

        if (last.endsWith(secondToLast)) {
          return "File:" + decodeURIComponent(secondToLast);
        }

        return "File:" + decodeURIComponent(last);
      }
    }

    throw new Error("no identifier in URL");
  };

  const handleWikiMediaPhoto = useCallback(async (url: string) => {
    const identifier = wikiMediaIdentifierFromURL(url);

    const wikimediaLicenseMap: Record<string, LicenseType> = {
      "cc by 2.0": "cc-2.0-by",
      "cc by-sa 2.0": "cc-2.0-by-sa",
      "cc by 2.5": "cc-2.5-by",
      "cc by-sa 2.5": "cc-2.5-by-sa",
      "cc by 3.0": "cc-3.0-by",
      "cc by-sa 3.0": "cc-3.0-by-sa",
      "cc by 4.0": "cc-4.0-by",
      "cc by-sa 4.0": "cc-4.0-by-sa",
      "cc by-nd 2.0": "cc-2.0-by-nd",
      "public domain": "public-domain",
      "cc0" : "public-domain",
      "no restrictions" : "public-domain",
      "pd" : "public-domain",
      "gfdl" : "cc-2.0-by",
      "fal" : "cc-4.0-by-sa"
    }

    const response = await fetch("https://en.wikipedia.org/w/api.php?" + new URLSearchParams({
      titles: identifier,

      action: "query",
      format: "json",
      iiprop: "extmetadata|url",
      indexpageids: "1",
      origin: "*",
      prop: "imageinfo",
    }), {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    const imageinfo = result.query.pages[result.query.pageids["0"]].imageinfo["0"];
    const { extmetadata: metadata, url: imageURL } = imageinfo;

    const title = metadata.ObjectName["value"]

    const license = metadata.LicenseShortName["value"]

    const author = metadata.Artist["value"].replace(/<[^>]+>/g, '')

    setEditablePhoto(imageURL);
    setCropperOpen(true);

    onUpdatePhoto &&
      onUpdatePhoto(
        copyrightDisabled
          ? { uri: result }
          : {
            uri: result,
            licenseType: wikimediaLicenseMap[license.toLowerCase()],
            resourceURI: url,
            author: author,
            title: title
          }
      );
  }, [ onUpdatePhoto, copyrightDisabled ]);

  //when you drop something over the drop zone get the url
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const url = event.dataTransfer.getData('text/uri-list');

    if (url.includes("wikimedia")) {
      return handleWikiMediaPhoto(url);
    }

    if (event.dataTransfer.files[0]) {
      onDrop(event.dataTransfer.files)
    }

    fetch(url, { method: 'HEAD' })
      .then(res => {
        if (res.ok) {
          setEditablePhoto(url);
          setCropperOpen(true);
        }
      }).catch(err => console.log('Error:', err));
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onFileDialogCancel: onTouchPhoto,
    disabled: displayOnly,
  });

  const onConfirmCrop = (result: string) => {
    onUpdatePhoto &&
      onUpdatePhoto(
        copyrightDisabled
          ? { uri: result }
          : {
            uri: result
          }
      );
    setCropperOpen(false);
  };

  return (
    <TitleCard title={label} className={className}>
      <Dropzone
        {...getRootProps({ className: "dropzone" })}
        isDragActive={isDragActive}
        displayOnly={!!displayOnly}
        onDrop={handleDrop}
        onDragOver={enableDropping}
      >
        {photo?.uri && !errors?.uri ? (
          <CardPhoto src={photo.uri as string} />
        ) : (
          <PlaceholderContainer>
            <Icon as={displayOnly ? MdError : MdAddAPhoto} />
            <Prompt>
              {displayOnly
                ? "Image not found"
                : "Drag and drop a photo, or click to upload."}
            </Prompt>
            {touches && errors?.uri && <ErrorText>{errors.uri}</ErrorText>}
          </PlaceholderContainer>
        )}

        <input style={{ outline: "none" }} {...getInputProps()} />
      </Dropzone>
      {photo?.uri && !copyrightDisabled && (
        <Copyright
          photo={photo}
          onUpdatePhoto={(photo) => onUpdatePhoto && onUpdatePhoto(photo)}
          onTouchedField={(field) => onTouchedField && onTouchedField(field)}
          displayOnly={!!displayOnly}
          touches={touches}
          errors={errors}
        />
      )}
      <Cropper
        src={editablePhoto}
        isVisible={isCropperOpen}
        onConfirmCrop={onConfirmCrop}
        onCancel={() => setCropperOpen(false)}
        aspectRatio={cropAspectRatio}
      />
    </TitleCard>
  );
}
