import React, { useEffect, useMemo, useState } from "react";
import styled from "../../../../theme/styled-components";
import { TitleCard } from "../../../../components/Cards/TitleCard";
import { PhotoInput } from "../../../../components/PhotoInput/PhotoInput";
import { BaseText, PageTitle } from "../../../../components/Text";
import { useQuery } from "../../../../CustomHooks";
import { Header } from "../../Shared/Header";
import _ from "lodash";
import { Input } from "../../../../components/Inputs/Input";
import { Formik, useFormik } from "formik";
import { ConfirmationButton } from "../../../../components/Buttons/ConfirmationButton";
import { useHistory } from "react-router";
import { SelectInput } from "../../../../components/Inputs/SelectInput";
import { roleOptions } from "../UserOverview/UserOverview";
import * as Yup from "yup";
import { currentUserState, loadedUsersState } from "../../../../GlobalStore";
import { useRecoilState } from "recoil";
import { iniviteNewUser } from "../../../../backend/mutations/inviteNewuser";
import { Roles } from "@memorylanegames/types";
import { FormikPhotoInput } from "../../../../components/Formik/FormikPhotoInput";
import { FormikInput } from "../../../../components/Formik/FormikInput";
import { FormikSelectInput } from "../../../../components/Formik/FormikSelectInput";
import { updateUser } from "../../../../backend/mutations/updateUser";
import { DeleteUser } from "./DeleteUser";
import Modal from "react-modal";
import { defaultShadow } from "../../../../components/defaultShadow";

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-template-rows: auto;
  grid-template-areas:
    "user-photo-input progress"
    "user-details progress"
    "send-button progress";
  grid-column-gap: 25px;
`;

const DetailsCard = styled(TitleCard)`
  grid-area: user-details;
  margin-bottom: 2rem;
`;

const SendButton = styled(ConfirmationButton)`
  grid-area: send-button;
`;

const ProfilePicture = styled(FormikPhotoInput)`
  margin-bottom: 2rem;
`;

const StyledModal = styled(Modal)`
  align-self: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.plainWhite};
  outline: none;
  ${defaultShadow};
  border-radius: 12px;
  width: 400px;
  padding: 2rem;
`;

const ConfirmTitle = styled(BaseText)`
  text-align: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
  margin-bottom: 2rem;
`;

const modalStyles = {
  content: {
    top: "-50%",
    left: "-50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(150%, 150%)",
  },
};

type Details = {
  label: string;
  placeholder: string;
  id: "firstName" | "lastName" | "email" | "phoneNumber";
};

const details: Details[] = [
  {
    label: "First name",
    placeholder: "Enter the user's first name...",
    id: "firstName",
  },
  {
    label: "Last name",
    placeholder: "Enter the user's last name...",
    id: "lastName",
  },
  { label: "Email", placeholder: "Enter the user's email...", id: "email" },
  {
    label: "Phone number",
    placeholder: "Enter the user's phone number...",
    id: "phoneNumber",
  },
];

const adminRoles: Roles[] = ["super-admin", "admin"];

export function EditUser() {
  //
  const history = useHistory();

  const [loadedUsers] = useRecoilState(loadedUsersState);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const query = useQuery();
  const userId = query.get("userId") as string;
  const newUser = query.get("newUser") === "true";

  const [currentUser] = useRecoilState(currentUserState);

  const isAdmin = currentUser && adminRoles.includes(currentUser.details.role);

  const user = useMemo(
    () => _.find(loadedUsers, { details: { uid: userId } }),
    [userId, loadedUsers]
  );

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const initialFormValues = {
    profilePicture: user ? { uri: user.details.profilePicture } : null,
    firstName: user ? user.details.firstName : "",
    lastName: user ? user.details.lastName : "",
    email: user ? user.details.email : "",
    phoneNumber: user ? String(user.details.phoneNumber) : "",
    role: user ? user.details.role : "creator",
  };

  const validationSchema = Yup.object().shape({
    profilePicture: Yup.object().shape({
      uri: Yup.string()
        .matches(/^data:image|^https/, "The photo must a valid image format")
        .required("Please select a photo"),
    }),
    firstName: Yup.string().required("Enter the user's first name"),
    lastName: Yup.string().required("Enter the user's last name"),
    email: Yup.string()
      .email("Enter a valid email address")
      .required("Enter the user's email address"),
    phoneNumber: Yup.string().required("Enter the user's phone number"),
    role: Yup.string().required("Select a role for the user"),
  });

  useEffect(() => {
    setModalIsOpen(false);
  }, []);

  const onSubmit = async (values: typeof initialFormValues) => {
    try {
      setModalIsOpen(true);
      const { profilePicture, ...rest } = values;
      if (profilePicture) {
        if (newUser) {
          // Logic for inviting new user
          await iniviteNewUser({ ...rest, profilePicture: profilePicture.uri });
        } else {
          // Logic for updating user details
          await updateUser({
            ...rest,
            profilePicture: profilePicture.uri,
            uid: userId,
          });
        }
        // Finally navigate back to the main screen
        history.goBack();
      }
    } catch (error) {
      setModalIsOpen(false);
      alert(error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <>
          <Header showBackButton>
            <PageTitle>
              {newUser ? "Invite New User" : "Edit User Details"}
            </PageTitle>
          </Header>
          <Grid>
            <ProfilePicture
              name="profilePicture"
              label="Profile picture"
              cropAspectRatio={1}
              copyrightDisabled
            />
            <DetailsCard title="User details">
              {details.map((detail, index) => {
                const { id, placeholder, label } = detail;
                return (
                  <FormikInput
                    label={label}
                    name={id}
                    placeholder={placeholder}
                    bottomMargin
                  />
                );
              })}
              <FormikSelectInput
                label="Role"
                name="role"
                options={roleOptions}
              />
            </DetailsCard>
            <SendButton type="submit" onClick={() => formik.handleSubmit()}>
              {newUser ? "Send invite" : "Save user details"}
            </SendButton>
            {!newUser && isAdmin ? <DeleteUser /> : null}
            <StyledModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={modalStyles}
            >
              {" "}
              <ConfirmTitle>
                <br />
                Uploading User Data...
                <br />
              </ConfirmTitle>
            </StyledModal>
          </Grid>
        </>
      )}
    </Formik>
  );
}
