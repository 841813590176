import { Roles } from "@memorylanegames/types";
import React from "react";
import { useRecoilState } from "recoil";
import { BooleanParam, useQueryParam } from "use-query-params";
import { TitleCard } from "../../../../../../components/Cards/TitleCard";
import { FormikMultilineInput } from "../../../../../../components/Formik/FormikMultilineInput";
import { FormikPhotoInput } from "../../../../../../components/Formik/FormikPhotoInput";
import { currentUserState } from "../../../../../../GlobalStore";
import styled from "../../../../../../theme/styled-components";
import { originalGameState } from "../../GameStore";
import { DeleteGame } from "./DeleteGame";
import { General } from "./General/General";

const Grid = styled.div`
  width: 100%;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: masonry;
  grid-template-areas:
    "general-card cover-photo"
    "general-card status-card"
    "general-card notes-card";
  grid-column-gap: 50px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 50px;
`;

const Column = styled.div`
  flex: 1;
  & > div {
    margin-bottom: 50px;
  }
`;

const adminRoles: Roles[] = ["super-admin", "admin"];

export const Details = () => {
  //
  const [isEditing] = useQueryParam("isEditing", BooleanParam);

  const [currentUser] = useRecoilState(currentUserState);
  const [originalGame] = useRecoilState(originalGameState);

  const isAuthorOrAdmin =
    currentUser &&
    (adminRoles.includes(currentUser.details.role) ||
      (originalGame && originalGame.info.authorId === currentUser.details.uid));

  return (
    <Row>
      <Column>
        <General />
        {isAuthorOrAdmin && <DeleteGame />}
      </Column>
      <Column>
        <FormikPhotoInput
          name="coverPhoto"
          label="Cover Photo"
          displayOnly={!isEditing}
        />
        <TitleCard title="Notes">
          <FormikMultilineInput
            name="notes"
            disabled={!isEditing}
            label="Notes"
            placeholder="Additional Information..."
          />
        </TitleCard>
      </Column>
    </Row>
  );
};
