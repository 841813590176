export const theme = {
  colors: {
    paleBlue: "#C0DEEB",
    washedOutBlue: "rgba(50, 179, 235, 0.25)",
    vibrantBlue: "#00B1E9",
    darkBlue: "#2595C5",
    plainWhite: "#ffffff",
    black: "#000000",
    nearlyBlack: "#444444",
    grey: "#777777",
    lightGrey: "#cccccc",
    offWhite: "#eeeeee",
    errorRed: "#cc0000",
    nearlyWhite: "#FCFCFC",
    green: "#44BD32",
  },
};

export type Theme = typeof theme;
