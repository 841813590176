import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { MdAdd } from "react-icons/md";
import { useHistory, useRouteMatch } from "react-router";
import styled, { css } from "../../../../theme/styled-components";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { PageTitle } from "../../../../components/Text";
import { Header } from "../../Shared/Header";
import { Link } from "react-router-dom";
import {
  SearchBar,
  SortByInterface,
  FiltersInterface,
} from "../../Shared/SearchBar/SearchBar";
import {
  hasNextPageOfGamesState,
  loadedGamesState,
} from "../../../../GlobalStore";
import { GameCard } from "../../../../components/Cards/GameCard";
import _ from "lodash";
import { useLoadPaginatedGames } from "../../../../backend/queries/paginatedGames";
import { LoadMoreButton } from "../../../../components/Buttons/LoadMoreButton";
import { inherits } from "util";


const defaultFilterState: FiltersInterface = {
  status: {
    approved: true,
    "ready-for-release": true,
    pending: true,
    rejected: true,
  },
};

const sortBys: SortByInterface[] = [
  {
    label: "Date created",
    id: "dateCreated",
    order: "asc",
  },
  {
    label: "Likes",
    id: "likes",
    order: "asc",
  },
  {
    label: "Dislikes",
    id: "dislikes",
    order: "asc",
  },
];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GameList = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas: "game-card game-card";
  grid-gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas: "game-card";
  }
`;

const ContentCard = styled(GameCard)`
  grid-area: "game-card";
`;

export function GameSearch() {
  //
  const history = useHistory();
  let match = useRouteMatch();

  const [loadedGames] = useRecoilState(loadedGamesState);
  const [hasNextPage] = useRecoilState(hasNextPageOfGamesState);

  const initializeSearchValue = () => {
    return localStorage.getItem('searchTermStored') ?? '';
  };
  
  const initializeSearchData = () => {
    return localStorage.getItem('submittedSearchTermStored') ?? '';
  };
  

  const [filters, setFilters] = useState<FiltersInterface>(defaultFilterState);
  const [sortBy, setSortBy] = useState("dateCreated");
  const [searchTerm, setSearchTerm] = useState(initializeSearchValue());
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState(initializeSearchData());

  const loadGames = useLoadPaginatedGames();

  /*
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  */

  useEffect(() => {
    // Only load games if initial games have been cleared
    loadGames({
      sortBy,
      filters,
      searchTerm: submittedSearchTerm,
      reset: true,
    });
  }, [filters, sortBy, submittedSearchTerm]);

  // If there is a submitted search term and a user starts to change
  // the search term then reset it
  useEffect(() => {
    if (submittedSearchTerm !== "" && submittedSearchTerm !== searchTerm) {
      setSubmittedSearchTerm("");
    }
    localStorage.setItem('searchTermStored', searchTerm)
    localStorage.setItem('submittedSearchTermStored', submittedSearchTerm)
  }, [searchTerm, submittedSearchTerm]);

  return (
    <Container>
      <Header>
        <PageTitle>Content Management System</PageTitle>
        <Link to={`${match.url}/games?isEditing=true&currentQuestion=0`} style={{ textDecoration: "none", color: "inherit" }}>
          <ActionButton
            icon={MdAdd}
            text="Create Game"
          />
        </Link>
      </Header>
      <SearchBar
        filters={filters}
        sortBys={sortBys}
        sortBy={sortBy}
        searchTerm={searchTerm}
        onUpdateFilters={(filters) => setFilters(filters)}
        onUpdateSortBy={(sortBy) => setSortBy(sortBy)}
        onUpdateSearchTerm={(search) => setSearchTerm(search)}
        onEnter={() => {
          setSubmittedSearchTerm(searchTerm);
        }}
      />
      <GameList>
        {loadedGames.map((game) => (
          <Link to={`/dashboard/cms/games/${game.info.uid}?currentQuestion=0`} key={game.info.uid} style={{ textDecoration: "none", color: "inherit" }}>
            <ContentCard 
              title={game.title}
              label={game.info.language}
              pendingStatus={game.info.status}
              status={game.info.status}
              photo={game?.coverPhoto?.uri as string}
            />
          </Link>
        ))}
      </GameList>
      {
        hasNextPage && (
          <LoadMoreButton
            onClick={() => {
              loadGames({
                sortBy,
                filters,
                searchTerm: submittedSearchTerm,
              });
            }}
          >
            Load more
          </LoadMoreButton>
        )
      }
    </Container >
  );
}
