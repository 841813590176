import React from "react";
import styled from "../../../theme/styled-components";
import { BackButton } from "../../../components/Buttons/BackButton";
import { useHistory } from "react-router";

export const Container = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: ${(props) => (props.shouldHaveMargin ? 1 : 2)}rem;
  margin-bottom: 2rem;
`;

interface IHeaderInterface extends React.HTMLProps<HTMLDivElement> {
  showBackButton?: boolean;
  onPressBack?: () => void;
}

export function Header(props: IHeaderInterface) {
  //
  const { children, showBackButton, onPressBack } = props;
  const history = useHistory();

  return (
    <>
      {showBackButton && (
        <BackButton
          onClick={() => (onPressBack ? onPressBack() : history.goBack())}
        />
      )}
      <Container shouldHaveMargin={showBackButton}>{children}</Container>
    </>
  );
}
