import React, { useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GameSearch } from "./Search/GameSearch";
import { Game } from "./Game/Game";

export function CMS() {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <GameSearch />
      </Route>
      <Route path={`${match.path}/games/:gameId`}>
        <Game />
      </Route>
      <Route path={`${match.path}/games`}>
        <Game />
      </Route>
    </Switch>
  );
}
