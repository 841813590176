import React, { useMemo } from "react";
import { IconType } from "react-icons/lib";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "../../../../../../theme/styled-components";
import { BaseText } from "../../../../../../components/Text";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const NavButton = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-right: 15px;
  margin-left: 15px;
`;

const Numbers = styled.div`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface INumberContainerProps extends React.HTMLProps<HTMLDivElement> {
  isCurrent: boolean;
}

const NumberContainer = styled.div<INumberContainerProps>`
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(35px / 2);
  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
  background-color: ${(props) =>
    props.isCurrent && props.theme.colors.paleBlue};
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

interface INumberProps extends React.HTMLProps<HTMLHeadingElement> {
  isCurrent: boolean;
}

const Number = styled(BaseText)<INumberProps>`
  font-weight: 500;
  color: ${({ theme, isCurrent }) => {
    if (isCurrent) {
      return theme.colors.plainWhite;
    } else {
      return theme.colors.lightGrey;
    }
  }};
`;

interface IPaginationProps extends React.HTMLProps<HTMLDivElement> {
  currentIndex: number;
  maxIndex: number;
  onChangeIndex: (index: number) => void;
}

export function Pagination(props: IPaginationProps) {
  //
  const { currentIndex, maxIndex, onChangeIndex } = props;

  const numbersArray = useMemo(() => {
    let pages: number[] = [];
    if (currentIndex === 0) {
      pages = [currentIndex, currentIndex + 1, currentIndex + 2];
    } else if (currentIndex === maxIndex) {
      pages = [currentIndex - 2, currentIndex - 1, currentIndex];
    } else {
      pages = [currentIndex - 1, currentIndex, currentIndex + 1];
    }
    return pages.filter((item) => {
      return item >= 0 && item <= maxIndex;
    });
  }, [currentIndex, maxIndex]);

  return (
    <Container>
      <NavButton
        onClick={() => currentIndex !== 0 && onChangeIndex(currentIndex - 1)}
      >
        <Icon as={IoIosArrowBack} />
      </NavButton>
      <Numbers>
        {numbersArray.map((item) => (
          <NumberContainer
            key={item}
            onClick={() => onChangeIndex(item)}
            isCurrent={item === currentIndex}
          >
            <Number isCurrent={item === currentIndex}>{item + 1}</Number>
          </NumberContainer>
        ))}
      </Numbers>
      <NavButton
        onClick={() =>
          currentIndex < maxIndex && onChangeIndex(currentIndex + 1)
        }
      >
        <Icon as={IoIosArrowForward} />
      </NavButton>
    </Container>
  );
}
