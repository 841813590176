import React from "react";
import styled from "../../../../../../theme/styled-components";
import { BaseLabel } from "../../../../../../components/Text";

const ButtonContainer = styled.div`
  height: 50px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGrey};
  border-radius: 24px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.9;
  }
`;

const Text = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;

interface IActionButtonProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
}

export function ActionButton(props: IActionButtonProps) {
  //
  const { text, onClick } = props;

  return (
    <ButtonContainer onClick={onClick}>
      <Text>{text}</Text>
    </ButtonContainer>
  );
}
