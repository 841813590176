import { useField } from "formik";
import React from "react";
import {
  CreateableSelect,
  ICreateableSelectInputProps,
} from "../Inputs/CreateableSelect";

interface IFormikCreatableSelectProps extends ICreateableSelectInputProps {
  name: string;
}

export const FormikCreatableSelect = (props: IFormikCreatableSelectProps) => {
  //
  const { name, ...rest } = props;

  const [field, meta, helpers] = useField(name);

  return (
    <CreateableSelect
      isClearable
      isMulti
      {...field}
      {...rest}
      name={name}
      value={field.value.map((v: string) => ({
        label: v,
        value: v,
      }))}
      // When the value is updated, format and remove unnecessary characters
      onUpdateValue={(value) => {
        helpers.setValue(
          value.map((option) =>
            option.value
              .toLowerCase()
              .replace(/[^a-zA-Z0-9- ]+/g, "")
              .trim()
          )
        );
      }}
    />
  );
};
