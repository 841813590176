/*
  This bad boy lets us have proper TS intellisense on our theme prop
  any time we need it!
*/

import baseStyled, {
  ThemeProvider,
  ThemeContext,
  ThemedStyledInterface,
  css as baseCss,
  keyframes,
  CSSProp,
} from "styled-components";

import { Theme } from "./theme";
import { ThemedCssFunction } from "styled-components";

const styled = baseStyled as ThemedStyledInterface<Theme>;
const css = baseCss as ThemedCssFunction<Theme>;
export default styled;
export { ThemeProvider, ThemeContext, css, keyframes };

declare module "react" {
  interface Attributes {
    css?: CSSProp<Theme>;
  }
}
