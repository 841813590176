import { licenseCodes, licenseFields, Photo } from "@memorylanegames/types";
import * as React from "react";
import styled from "../../theme/styled-components";
import { OptionType } from "../Inputs/CreateableSelect";
import { Input } from "../Inputs/Input";
import { SelectInput } from "../Inputs/SelectInput";
import _ from "lodash";
import { Errors, Touches } from "./PhotoInput";

interface HumanReadableKeys {
  [key: string]: string;
}

const humanReadableKeys: HumanReadableKeys = {
  uri: "URI",
  resourceURI: "Resource URI",
  author: "Author",
  title: "Title",
  licenseType: "License Type",
  description: "Description",
};

const CopyrightSelect = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

const licenseOptions = (licenseCodes as any).map((licenseCode: any) => ({
  value: licenseCode.id,
  label: licenseCode.readableName,
}));

interface ICopyrightProps {
  photo?: Photo;
  touches?: Touches;
  errors?: Errors;
  displayOnly: boolean;
  onUpdatePhoto: (photo: Photo) => void;
  onTouchedField: (field: string) => void;
}

export const Copyright = (props: ICopyrightProps) => {
  //
  const {
    photo,
    touches,
    errors,
    displayOnly,
    onUpdatePhoto,
    onTouchedField,
  } = props;

  const selectedLicense = _.find(licenseOptions, { value: photo?.licenseType });

  const allFields = selectedLicense
    ? licenseFields[selectedLicense.value as "unsplash"]
    : [];

  if (!photo) return null;

  return (
    <>
      <CopyrightSelect>
        <SelectInput
          label="Copyright Information"
          placeholder={"Select a copyright type..."}
          onChange={(option: any) => {
            const requiredFields = licenseFields[
              option.value as "unsplash"
            ].reduce((obj, field) => {
              return { ...obj, [field]: undefined };
            }, {});
            onUpdatePhoto({
              uri: photo.uri,
              licenseType: (option as OptionType).value as any,
              ...requiredFields,
            });
          }}
          options={licenseOptions}
          isDisabled={displayOnly}
          value={_.find(licenseOptions, { value: photo.licenseType })}
          error={
            touches && errors && touches["licenseType"] && errors["licenseType"]
          }
          onBlur={() => onTouchedField && onTouchedField("licenseType")}
        />
      </CopyrightSelect>
      {allFields.map((field: string, index) => (
        <Input
          key={field}
          label={humanReadableKeys[field]}
          disabled={displayOnly}
          placeholder={`Please enter the correct ${field}...`}
          error={touches && errors && touches[field] && errors[field]}
          onChange={(e) =>
            onUpdatePhoto &&
            onUpdatePhoto({
              ...photo,
              [field]: (e.target as HTMLInputElement).value,
            })
          }
          value={photo[field as "licenseType"]}
          onBlur={() => onTouchedField(field)}
          style={{
            marginBottom: index < allFields.length - 1 ? "2rem" : "0rem",
          }}
        />
      ))}
    </>
  );
};
