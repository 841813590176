import React from "react";
import { MdSearch } from "react-icons/md";
import styled from "../../../../theme/styled-components";
import { defaultInputCSS } from "../../../../components/Inputs/Input";

const Container = styled.div`
  display: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled.input`
  ${defaultInputCSS};
  width: 300px;
  height: 50px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

interface IIconProps extends React.HTMLProps<HTMLDivElement> {
  queryEntered: boolean;
}

const Icon = styled.div<IIconProps>`
  position: absolute;
  right: 50px;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.lightGrey};
  ${({ queryEntered }) => {
    if (queryEntered) {
      return { opacity: 0.0 };
    }
  }}
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ISearchProps {
  searchTerm: string;
  onUpdateSearchTerm: (search: string) => void;
  onEnter?: () => void;
}

export function Search({
  searchTerm,
  onUpdateSearchTerm,
  onEnter,
}: ISearchProps) {
  return (
    <Container>
      <Input
        placeholder="Search..."
        value={searchTerm}
        onChange={(event) => onUpdateSearchTerm(event.target.value)}
        onKeyDown={({ key }) => key === "Enter" && onEnter && onEnter()}
      />
      <Icon queryEntered={searchTerm !== ""}>
        <MdSearch />
      </Icon>
    </Container>
  );
}
