import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router";
import styled from "../../../../theme/styled-components";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { ItemCard } from "../../../../components/Cards/ItemCard";
import { PageTitle } from "../../../../components/Text";
import { Header } from "../../Shared/Header";
import { SearchBar, SortByInterface } from "../../Shared/SearchBar/SearchBar";
import { UserCard } from "../../../../components/Cards/UserCard";
import { useLoadPaginatedUsers } from "../../../../backend/queries/paginatedUsers";
import {
  currentUserState,
  hasNextPageOfUsersState,
  loadedUsersState,
} from "../../../../GlobalStore";
import { useRecoilState } from "recoil";
import { LoadMoreButton } from "../../../../components/Buttons/LoadMoreButton";
import { Roles } from "@memorylanegames/types";

// const filterOptions = [
//   {
//     filterGroupKey: "role",
//     filterGroupTitle: "Role",
//     filtersOptions: [
//       { value: "super-admin", label: "Super admin" },
//       { value: "admin", label: "Admin" },
//       { value: "reviewer", label: "Reviewer" },
//       { value: "creator", label: "Creator" },
//     ],
//   },
// ];

const sortBys: SortByInterface[] = [
  {
    label: "Name",
    id: "name",
    order: "asc",
  },
];

const UserList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "user-card user-card";
  grid-gap: 50px;
`;

const adminRoles: Roles[] = ["admin", "super-admin"];

export function UserSearch() {
  //
  const history = useHistory();

  const [currentUser] = useRecoilState(currentUserState);

  const [loadedUsers] = useRecoilState(loadedUsersState);
  const [hasNextPage] = useRecoilState(hasNextPageOfUsersState);

  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const loadUsers = useLoadPaginatedUsers();

  let userCount;

  useEffect(() => {
    // Only load games if initial games have been cleared
    loadUsers({
      sortBy,
      filters,
      searchTerm: submittedSearchTerm,
      reset: true,
    });
  }, [filters, sortBy, submittedSearchTerm]);

  // If there is a submitted search term and a user starts to change
  // the search term then reset it
  useEffect(() => {
    if (submittedSearchTerm !== "" && submittedSearchTerm !== searchTerm) {
      setSubmittedSearchTerm("");
    }
  }, [searchTerm, submittedSearchTerm]);

  return (
    <>
      <Header>
        <PageTitle>Admin</PageTitle>
        {currentUser && adminRoles.includes(currentUser.details.role) && (
          <ActionButton
            icon={MdAdd}
            text="Add User"
            onClick={() =>
              history.push(`/dashboard/admin/edit-user?newUser=true`)
            }
          />
        )}
      </Header>
      <SearchBar
        filters={filters}
        sortBys={sortBys}
        sortBy={sortBy}
        searchTerm={searchTerm}
        onUpdateFilters={(filters) => setFilters(filters)}
        onUpdateSortBy={(sortBy) => setSortBy(sortBy)}
        onUpdateSearchTerm={(search) => setSearchTerm(search)}
        onEnter={() => {
          setSubmittedSearchTerm(searchTerm);
        }}
      />
      <UserList>
        {loadedUsers.map((user, index) => (
          <UserCard
            key={user.details.uid}
            title={user.details.firstName + " " + user.details.lastName}
            label={user.details.role}
            photo={user.details.profilePicture as string}
            onClick={() =>
              history.push(`/dashboard/admin/users/${user.details.uid}`)
            }
          />
        ))}
        {hasNextPage && (
          <LoadMoreButton
            onClick={() => {
              loadUsers({
                sortBy,
                filters,
                searchTerm: submittedSearchTerm,
              });
            }}
          >
            Load more
          </LoadMoreButton>
        )}
      </UserList>
    </>
  );
}
