import { CMSUser, GameType } from "@memorylanegames/types";
import { atom } from "recoil";

export const loadedGamesState = atom<GameType[]>({
  key: "loadedGamesState",
  default: [],
});

export const hasNextPageOfGamesState = atom<boolean>({
  key: "hasNextPageOfGamesState",
  default: false,
});

export const loadedUsersState = atom<CMSUser[]>({
  key: "loadedUsersState",
  default: [],
});

export const hasNextPageOfUsersState = atom<boolean>({
  key: "hasNextPageOfUsersState",
  default: false,
});

export const hasCheckedAuthState = atom<boolean>({
  key: "hasCheckedAuthState",
  default: false,
});

export const currentUserState = atom<CMSUser | null>({
  key: "currentUserState",
  default: null,
});
