import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "../../theme/styled-components";
import { Error } from "../Inputs/Input";
import { useSpring } from "react-spring";
import { Theme } from "../../theme/theme";
import { ITitleInputProps, TitleInput } from "../Inputs/TitleInput";
import { useField } from "formik";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;

interface IFormikTitleInputProps extends ITitleInputProps {
  name: string;
}

export function FormikTitleInput(props: IFormikTitleInputProps) {
  //
  const { className, disabled, name, ...rest } = props;

  const [isEditing, setEditing] = useState(false);
  const [isHovering, setHovering] = useState(false);

  const theme = useContext<Theme>(ThemeContext);

  const [field, meta] = useField(name);

  const error = meta.touched && meta.error;
  const { value } = field;

  const placeholderStyles = useSpring({
    opacity: !isEditing && value === "" && !error ? 1.0 : 0.0,
    left: !isEditing && value === "" && !error ? 0 : 15,
    config: { duration: 200 },
  });

  const inputStyles = useSpring({
    opacity: isEditing || !!error || value !== "" ? 1.0 : 0.0,
    borderColor:
      isEditing || !!error || isHovering
        ? !!error
          ? theme.colors.errorRed
          : "#ccccccff"
        : "#00000000",
    paddingLeft: isEditing || isHovering || !!error ? "15px" : "0px",
    config: { duration: 200 },
  });

  return (
    <Container>
      <TitleInput
        {...rest}
        {...field}
        onBlur={(e) => {
          setEditing(false);
          field.onBlur(e);
        }}
        onFocus={(e) => {
          setEditing(true);
        }}
        isEditing={isEditing}
        disabled={disabled}
        value={value}
        placeholderStyles={placeholderStyles}
        inputStyles={inputStyles}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}
