import pica from "pica";

export const uriToBlob = async (uri: string): Promise<Blob> => {
  // Turn the base64 string into a HTML Image Element
  const inputImage = new Image();
  inputImage.src = uri;
  // Create a canvas to perform the image manipulation on
  const resizedCanvas = document.createElement("canvas");
  const aspectRatio = inputImage.width / inputImage.height;
  resizedCanvas.height = 720;
  resizedCanvas.width = 720 * aspectRatio;
  // Perform a resize op using pica
  const resize = await pica().resize(inputImage, resizedCanvas, {
    unsharpAmount: 80,
    unsharpRadius: 0.6,
    unsharpThreshold: 2,
  });
  // Convert the result to a blob that can actually be uploaded to firebase
  const blob = await new Promise<Blob>((resolve, reject) =>
    resize.toBlob(
      (blob) => (blob ? resolve(blob) : reject()),
      "image/jpeg",
      0.9
    )
  );
  return blob;
};
