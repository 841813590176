import React, { useState } from "react";
import { MdFilter, MdFilterList } from "react-icons/md";
import styled from "../../../../theme/styled-components";
import { ConfirmationButton } from "../../../../components/Buttons/ConfirmationButton";
import { BaseLabel, BaseText } from "../../../../components/Text";
import { DropDownButton } from "./DropDownButton";
import { FiltersInterface } from "./SearchBar";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  min-width: 250px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
`;

const Column = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  pointer-events: auto;
`;

const FilterType = styled(BaseLabel)`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  display: block;
  margin-right: 10px;
  height: 12.8px;
  width: 12.8px;
  border-radius: 2px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  &:checked {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

const Label = styled(BaseText)`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 0.9rem;
  font-weight: 500;
`;

const ActionBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Submit = styled(ConfirmationButton)`
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  box-sizing: border-box;
  font-size: 1.1rem;
  pointer-events: auto;
`;

function capitalise(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export interface IFilterProps {
  filters: FiltersInterface;
  onUpdateFilters: (filters: FiltersInterface) => void;
}

export function Filter(props: IFilterProps) {
  //
  const { filters, onUpdateFilters } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <DropDownButton
      icon={MdFilterList}
      label="Filter"
      menuOpen={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      onCloseMenu={() => setMenuOpen(false)}
      menu={
        <Menu>
          <Filters>
            {Object.keys(filters).map((filterType, index) => (
              <Column key={filterType}>
                <FilterType>{capitalise(filterType)}</FilterType>
                {Object.keys(filters[filterType]).map((filter, index) => (
                  <Item key={filter}>
                    <Checkbox
                      id={filterType + "~" + filter}
                      checked={filters[filterType][filter]}
                      onChange={(event) => {
                        const newFilters = Object.assign({}, filters);
                        newFilters[filterType][filter] = event.target.checked;
                        onUpdateFilters(newFilters);
                      }}
                    />
                    <Label as="label" htmlFor={filterType + "~" + filter}>
                      {capitalise(filter)}
                    </Label>
                  </Item>
                ))}
              </Column>
            ))}
          </Filters>
          {/* <ActionBar>
            <Submit onClick={() => setMenuOpen(false)}>Submit</Submit>
          </ActionBar> */}
        </Menu>
      }
    />
  );
}
