import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled, { ThemeProvider } from "./theme/styled-components";
import { RecoilRoot, useRecoilState } from "recoil";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { Login } from "./screens/Login/Login";
import { theme } from "./theme/theme";
import { QueryParamProvider } from "use-query-params";
import { PrivateRoute } from "./components/PrivateRoute";
import firebase from "firebase";
import { hasCheckedAuthState } from "./GlobalStore";
import { Helmet } from "react-helmet";

const {
  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_DATABASE_URL,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_MESSAGING_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEASUREMENT_ID,
} = process.env;

export const firebaseConfig = {
  apiKey: REACT_APP_FB_API_KEY,
  authDomain: REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: REACT_APP_FB_DATABASE_URL,
  projectId: REACT_APP_FB_PROJECT_ID,
  storageBucket: REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: REACT_APP_FB_APP_ID,
  measurementId: REACT_APP_FB_MEASUREMENT_ID,
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${theme.colors.plainWhite};
`;

function App() {
  //
  const [hasCheckedAuth, setHasCheckedAuth] = useRecoilState(
    hasCheckedAuthState
  );

  useEffect(() => {
    // Check the auth state for firebase - if there is a session then
    // navigate to the homescreen
    firebase.auth().onAuthStateChanged((user) => {
      setHasCheckedAuth(true);
    });
  }, [setHasCheckedAuth]);

  if (!hasCheckedAuth) {
    return null;
  }

  return (
    <Container>
      <Helmet
        defaultTitle="Memory Lane Games"
        titleTemplate="%s - Memory Lane Games"
      />

      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <PrivateRoute path="/dashboard" component={Dashboard} />
          </Switch>
        </QueryParamProvider>
      </Router>
    </Container>
  );
}

function AppWithProviders() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default AppWithProviders;
