import React, { useRef } from "react";
import { IconType } from "react-icons";
import styled from "../../../../theme/styled-components";
import { Card } from "../../../../components/Cards/Card";
import { BaseLabel } from "../../../../components/Text";
import OutsideClickHandler from "react-outside-click-handler";

const Container = styled.div`
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Button = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border-radius: 12px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.offWhite};
  border-width: 2.5px;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.grey};
  margin-right: 10px;
`;

const Label = styled(BaseLabel)`
  margin: 0;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Menu = styled(Card)<any>`
  padding: 0;
  position: absolute;
  width: auto;
  top: 45px;
  left: 0;
  cursor: default;
  ${({ visible }) => {
    if (!visible) {
      return { opacity: 0.0, pointerEvents: "none" };
    }
  }}
  transition: all 0.3s;
  z-index: 9999;
`;

interface IDropDownButtonProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "label"> {
  icon: IconType;
  label: React.ReactNode;
  menuOpen: boolean;
  menu: React.ReactNode;
  onCloseMenu: () => void;
}

export function DropDownButton(props: IDropDownButtonProps) {
  //
  const {
    onClick,
    onCloseMenu,
    icon,
    label,
    menuOpen,
    menu,
    className,
  } = props;

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (menuOpen) {
          onCloseMenu();
        }
      }}
    >
      <Container className={className}>
        <Button onClick={onClick}>
          <Icon as={icon} />
          <Label as="div">{label}</Label>
        </Button>
        <Menu visible={menuOpen} onClick={() => {}}>
          {menu}
        </Menu>
      </Container>
    </OutsideClickHandler>
  );
}
