import React from "react";
import { Preview } from "./Preview/Preview";
import { TitleBar } from "./TitleBar";
import { PreventExit } from "./PreventExit";
import { UploadingModal } from "./UploadingModal";
import { Details } from "./Details/Details";

export function Overview() {
  return (
    <>
      <PreventExit />
      <UploadingModal />
      <TitleBar />
      <Preview />
      <Details />
    </>
  );
}
