import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "../../theme/styled-components";
import { Admin } from "./Admin/Admin";
import { Analytics } from "./Analytics/Analytics";
import { CMS } from "./CMS/CMS";
import { Overview } from "./Overview/Overview";
import { Sidebar } from "./Sidebar/Sidebar";
import firebase from "firebase";
import { getUserById } from "../../backend/queries/getUserById";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState, hasCheckedAuthState } from "../../GlobalStore";
import { Profile } from "./Profile/Profile";

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow-x: hidden;
  min-width: 1400px;
`;

const Window = styled.div`
  min-height: 100%;
  padding: 25px;
  width: calc(100vw - 250px - 15px);
  margin-left: 250px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

export function Dashboard() {
  //
  let match = useRouteMatch();

  const [, setCurrentUser] = useRecoilState(currentUserState);

  // Fetch the user details upon the
  useEffect(() => {
    const getCurrentUser = async () => {
      const firebaseAuth = firebase.auth().currentUser;
      if (firebaseAuth) {
        const user = await getUserById(firebaseAuth.uid);
        if (user) {
          setCurrentUser(user);
        }
      }
    };
    getCurrentUser();
  }, [setCurrentUser]);
  
  

  return (
    <Container>
      <Window>
        <Switch>
          <Route exact path={`${match.path}`}>
            <Overview />
          </Route>
          <Route path={`${match.path}/cms`}>
            <CMS />
          </Route>
          <Route path={`${match.path}/analytics`}>
            <Analytics />
          </Route>
          <Route path={`${match.path}/admin`}>
            <Admin />
          </Route>
          <Route path={`${match.path}/profile`}>
            <Profile />
          </Route>
        </Switch>
      </Window>
      <Sidebar />
    </Container>
  );
}
