import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import { loadedGamesState } from "../../../../GlobalStore";
import { useHistory, useParams } from "react-router";
import { Overview } from "./Overview/Overview";
import { EditQuestion } from "./EditQuestion";
import { GameType } from "@memorylanegames/types";
import { BooleanParam, NumberParam, useQueryParam } from "use-query-params";
import { getGameById } from "../../../../backend/queries/getGameById";
import { Formik } from "formik";
import * as Yup from "yup";
import { originalGameState, uploadingModalOpenState } from "./GameStore";
import { blankGame } from "./form/blanks";
import { createValidationSchema, Fields } from "./form/schemas";
import { validateAndUpload } from "./form/submission";
import { Helmet } from "react-helmet";

export function Game() {
  //
  const history = useHistory();

  const [, setUploadingModalOpen] = useRecoilState(uploadingModalOpenState);

  const games = useRecoilValue(loadedGamesState);
  const { gameId } = useParams<any>();

  const [currentQuestion, setCurrentQuestion] = useQueryParam(
    "currentQuestion",
    NumberParam
  );
  const [isEditingQuestion] = useQueryParam("isEditingQuestion", BooleanParam);
  const [, setIsEditing] = useQueryParam("isEditing", BooleanParam);

  const [initialFormValues, setInitialFormValues] = useState<
    Fields | undefined
  >();

  const [originalGame, setOriginalGame] = useRecoilState<GameType | null>(
    originalGameState
  );

  const setQuestionIndexInBounds = useCallback((game: GameType) => {
    if (typeof currentQuestion === "number") {
      setCurrentQuestion(
        Math.min(Math.max(0, currentQuestion), game.questions.length - 1)
      );
    } else {
      setCurrentQuestion(0);
    }
  }, []);

  // If there is no game then put it straight into editing mode
  useEffect(() => {
    setUploadingModalOpen(false);
    const convertGameToForm = (game: GameType): Fields => {
      return {
        title: game.title,
        coverPhoto: game.coverPhoto,
        keywords: game.keywords,
        language: game.info.language,
        location: game.info.location,
        status: game.info.status,
        paid: game.info.paid ? "paid" : "free",
        notes: game.info.notes ? game.info.notes : "",
        questions: game.questions.map((q) => ({
          title: q.title,
          questionPhoto: q.questionPhoto,
          correctAnswerPhoto: q.correctAnswerPhoto,
          correctAnswer: q.correctAnswer,
          false1: q.incorrectAnswers[0],
          false2: q.incorrectAnswers[1],
          false3: q.incorrectAnswers[2],
        })),
      };
    };
    const getSpecifiedGame = async () => {
      if (gameId) {
        // See if the game was accessed from the local state
        const localGame = _.find(games, { info: { uid: gameId } });
        if (localGame) {
          setOriginalGame(localGame);
          setInitialFormValues(convertGameToForm(localGame));
          setQuestionIndexInBounds(localGame);
        } else {
          // Else they accessed it directly e.g. by clicking on the game URL
          // Try get the game from the server with this ID - if it doesn't return
          // anything then redirect to this page w/o an ID to set a blank game
          const serverGame = await getGameById(gameId);
          if (serverGame) {
            setOriginalGame(serverGame);
            setInitialFormValues(convertGameToForm(serverGame));
            setQuestionIndexInBounds(serverGame);
          } else {
            history.push(
              "/dashboard/cms/games?isEditing=true&currentQuestion=0"
            );
          }
        }
      } else {
        // If there is no game ID then set it up with a blank game ready for editing
        setIsEditing(true);
        setInitialFormValues(blankGame);
        setCurrentQuestion(0);
        // VERY important that the game gets set back to null to avoid stale state
        // e.g. a new game using a previous game's ID
        setOriginalGame(null);
      }
    };
    getSpecifiedGame();
  }, [
    games,
    gameId,
    setIsEditing,
    history,
    setCurrentQuestion,
    setQuestionIndexInBounds,
    setOriginalGame,
    setUploadingModalOpen,
  ]);

  if (!initialFormValues) {
    return null;
  }

  if (currentQuestion === undefined || currentQuestion === null) {
    return null;
  }

  const validationSchema = Yup.lazy((values: Fields): any =>
    createValidationSchema(values)
  );

  const onSubmit = async (values: Fields) => {
    try {
      setIsEditing(false)
      // Set the modal to be open while uploading
      setUploadingModalOpen(true);

      values.title = values.title.trim();

      // Filter out blank spaces from the keyword values
      values.keywords = values.keywords.filter(
        (keyword) => keyword !== " " && keyword !== ""
      );

      await validateAndUpload(values, originalGame);
      // Wooo it did it! Let's close the window
      history.push("/dashboard/cms");
    } catch (error) {
      alert(error);
      setUploadingModalOpen(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{originalGame?.title}</title>
      </Helmet>

      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {!isEditingQuestion ? <Overview /> : <EditQuestion />}
      </Formik>
    </>
  );
}
