import { useField, useFormikContext } from "formik";
import React from "react";
import { IPhotoInputProps, PhotoInput } from "../PhotoInput/PhotoInput";
import { Photo } from "@memorylanegames/types";
import _ from "lodash";

interface IFormikPhotoInputProps extends IPhotoInputProps {
  name: string;
}

export const FormikPhotoInput = (props: IFormikPhotoInputProps) => {
  //
  const { name, ...rest } = props;

  const {
    values,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext();

  // have to use lodash to convert dot notation to object access ourself
  const value = _.get(values as any, name) as Photo;

  return (
    <PhotoInput
      photo={value ? value : {}}
      onUpdatePhoto={(photo) => setFieldValue(name, { ...value, ...photo })}
      onTouchedField={(key) => setFieldTouched(`${name}.${key}`, true)}
      touches={(touched as any)[name]}
      errors={(errors as any)[name]}
      {...rest}
    />
  );
};
