import styled from "../../theme/styled-components";
import { defaultShadow } from "../defaultShadow";
import { BaseText } from "../Text";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.plainWhite};
  ${defaultShadow};
  height: fit-content;
  width: 100%;
`;

export const HoverCard = styled(Card)`
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
  transition: all 0.2s;
`;

export const Details = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const Title = styled(BaseText)`
  font-weight: 500;
  font-size: 1.2rem;
`;

export const Label = styled(Title)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

export const CardPhoto = styled.img`
  height: auto;
  width: 100%;
  border-radius: 12px;
  background-size: cover;
  object-fit: contain;
`;
