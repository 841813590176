import { GameStatuses, Location, Photo } from "@memorylanegames/types";
import * as Yup from "yup";

export interface Fields {
  title: string;
  coverPhoto: Photo;
  questions: {
    title: string;
    questionPhoto: Photo;
    correctAnswerPhoto: Photo | null;
    correctAnswer: string;
    false1: string;
    false2: string;
    false3: string;
  }[];
  keywords: string[];
  language: string;
  location: Location | null;
  status: GameStatuses;
  paid: "free" | "paid";
  notes: string;
}

export const uriValidation = Yup.string()
  .matches(/^data:image|^https/, "The photo must a valid image format")
  .required("Please select a photo");

export const photoValidation = Yup.object().shape({
  uri: uriValidation,
});

export const conditionalSchemas: { [key: string]: any } = {
  uri: uriValidation,
  licenseType: Yup.string().required(
    "Please select a copyright license type..."
  ),
  author: Yup.string().required("Please specify the author of the image..."),
  resourceURI: Yup.string().required(
    "Please specify the resource URI to the image..."
  ),
  title: Yup.string().required("Please specify the title of the image..."),
};

const duplicatesAnswerCheck = (value: string, valuesArray: string[]) => {
  const matches = valuesArray.filter((item: any) => item === value).length;
  return matches === 1; // returns true if passes validation (i.e. no duplicates)
};

export const createValidationSchema = (values: Fields) => {
  // Compute what fields are now required for the cover photo
  const requiredPhotoFields = () => {
    return Object.keys(values["coverPhoto"]).reduce((obj, item) => {
      return { ...obj, [item]: conditionalSchemas[item] };
    }, {});
  };
  return Yup.object().shape({
    title: Yup.string().required("Please enter a question title"),
    questions: Yup.array().of(
      Yup.lazy((question: any) => {
        const requiredPhotoFields = (
          key: "questionPhoto" | "correctAnswerPhoto"
        ) => {
          return Object.keys(question[key] ? question[key] : {}).reduce(
            (obj, item) => {
              return { ...obj, [item]: conditionalSchemas[item] };
            },
            {}
          );
        };
        const {
          correctAnswerPhoto,
          correctAnswer,
          false1,
          false2,
          false3,
        } = question as Fields["questions"][0];
        const answers = [correctAnswer, false1, false2, false3];
        return Yup.object().shape({
          title: Yup.string().required("Please enter a question title"),
          questionPhoto: photoValidation
            .shape({ ...requiredPhotoFields("questionPhoto") })
            .required(),
          correctAnswerPhoto: correctAnswerPhoto?.uri
            ? photoValidation
                .shape({
                  ...requiredPhotoFields("correctAnswerPhoto"),
                })
                .required()
            : Yup.object().nullable(),
          correctAnswer: Yup.string()
            .required("Enter the correct answer")
            .test("duplicates-check", "Duplicate answer", () =>
              duplicatesAnswerCheck(correctAnswer, answers)
            ),
          false1: Yup.string()
            .required("Enter a false answer")
            .test("duplicates-check", "Duplicate answer", () =>
              duplicatesAnswerCheck(false1, answers)
            ),
          false2: Yup.string()
            .required("Enter a false answer")
            .test("duplicates-check", "Duplicate answer", () =>
              duplicatesAnswerCheck(false2, answers)
            ),
          false3: Yup.string()
            .required("Enter a false answer")
            .test("duplicates-check", "Duplicate answer", () =>
              duplicatesAnswerCheck(false3, answers)
            ),
        });
      })
    ),
    coverPhoto: photoValidation.shape({ ...requiredPhotoFields() }).required(),
    keywords: Yup.array().of(Yup.string()),
    language: Yup.string().required(),
    status: Yup.string().required(),
    paid: Yup.string().required(),
    notes: Yup.string(),
  });
};
