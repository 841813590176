import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { Header } from "../../Shared/Header";
import { PageTitle } from "../../../../components/Text";
import { ActionButton } from "../../../../components/Buttons/ActionButton";
import { MdEdit } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import { TitleCard } from "../../../../components/Cards/TitleCard";
import styled from "../../../../theme/styled-components";
import { Input } from "../../../../components/Inputs/Input";
import { Card } from "../../../../components/Cards/Card";
import { PhotoInput } from "../../../../components/PhotoInput/PhotoInput";
import { SelectInput } from "../../../../components/Inputs/SelectInput";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentUserState, loadedUsersState } from "../../../../GlobalStore";
import { CMSUser, Roles } from "@memorylanegames/types";

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "user-photo-card details-card";
  grid-gap: 50px;
`;

const UserPhotoCard = styled(PhotoInput)`
  grid-area: "user-photo-card";
`;

const DetailsCard = styled(TitleCard)`
  grid-area: "details-card";
  & > :not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const roleOptions = [
  { value: "super-admin", label: "Super admin" },
  { value: "admin", label: "Admin" },
  { value: "reviewer", label: "Reviewer" },
  { value: "creator", label: "Creator" },
];

const adminRoles: Roles[] = ["admin", "super-admin"];

export function UserOverview() {
  //
  const history = useHistory();

  const [loadedUsers] = useRecoilState(loadedUsersState);

  const [currentUser] = useRecoilState(currentUserState);

  const { id } = useParams<any>();
  const user = useMemo(() => _.find(loadedUsers, { details: { uid: id } }), [
    id,
    loadedUsers,
  ]) as CMSUser | undefined;

  if (!user) {
    return <h1>User not found!</h1>;
  }

  const {
    details: {
      firstName,
      lastName,
      profilePicture,
      email,
      role,
      uid,
      phoneNumber,
    },
  } = user;

  const detailsFields = [
    { label: "First name", value: firstName },
    { label: "Last name", value: lastName },
    { label: "Email address", value: email },
    { label: "Phone number", value: phoneNumber ? phoneNumber : "" },
  ];

  return (
    <>
      <Header showBackButton>
        <PageTitle>{firstName + " " + lastName}</PageTitle>
        {currentUser && adminRoles.includes(currentUser.details.role) && (
          <ActionButton
            icon={AiTwotoneEdit}
            text="Edit User"
            onClick={() =>
              history.push(
                `/dashboard/admin/edit-user?userId=${uid}&newUser=false`
              )
            }
          />
        )}
      </Header>
      <Grid>
        <UserPhotoCard
          label="User Photo"
          photo={{ uri: profilePicture }}
          displayOnly
          copyrightDisabled
        />
        <DetailsCard title="User details">
          {detailsFields.map((field, index) => (
            <Input
              key={field.label}
              label={field.label}
              value={field.value}
              disabled
              bottomMargin
            />
          ))}
          <SelectInput
            label="Role"
            isDisabled
            value={_.find(roleOptions, { value: role })}
            options={roleOptions}
            noOptionsText="No matching roles found"
          />
        </DetailsCard>
      </Grid>
    </>
  );
}
