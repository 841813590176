import React from "react";
import Select, { Styles, Props as SelectProps } from "react-select";
import styled, { css } from "../../theme/styled-components";
import { theme } from "../../theme/theme";
import { Error } from "./Input";
import { BaseLabel } from "../Text";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
`;

export const Label = styled(BaseLabel)``;

export const defaultTextStyle = {
  fontFamily: "Roboto",
  fontSize: "1.2rem",
  color: theme.colors.nearlyBlack,
};

// https://github.com/JedWatson/react-select/issues/2770
export const customStyles: Styles = {
  container: (provided) => {
    return { ...provided, width: "100%", height: "3.5rem", padding: 0 };
  },
  control: (provided, state) => {
    return {
      ...provided,
      height: "3.5rem",
      borderStyled: "solid",
      borderRadius: "12px",
      borderWidth: "2px",
      borderColor: state.selectProps.error
        ? theme.colors.errorRed
        : theme.colors.offWhite,
      paddingLeft: "1rem",
      paddingRight: "1rem",
      boxSizing: "border-box",
      boxShadow: "none",
      backgroundColor: state.isDisabled
        ? "rgba(239, 239, 239, 0.3)"
        : provided.backgroundColor,

      "&:hover": {},
    };
  },
  input: (provided) => {
    return {
      ...provided,
      fontFamily: "Roboto",
      fontSize: "1.2rem",
      maxWidth: "200px",
    };
  },
  placeholder: (provided) => {
    return { ...provided, ...defaultTextStyle };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      ...defaultTextStyle,
      color: defaultTextStyle.color,
    };
  },
  valueContainer: (provided) => {
    return { ...provided, padding: 0 };
  },
  indicatorSeparator: (provided) => {
    return {};
  },
  dropdownIndicator: (provided) => {
    return { ...provided, padding: 0 };
  },
  option: (provided) => {
    return { ...provided, ...defaultTextStyle };
  },
};

export interface ISelectInputProps extends SelectProps {
  label: string;
  noOptionsText?: string;
  error?: string | boolean;
}

export function SelectInput(props: ISelectInputProps) {
  //
  const { label, styles, options, noOptionsText, error, ...rest } = props;

  return (
    <Container>
      <Label>{props.label}</Label>
      <Select
        {...rest}
        error={Boolean(error)}
        styles={{ ...customStyles, ...styles }}
        options={options}
        noOptionsMessage={() => (noOptionsText ? noOptionsText : "No options")}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}
