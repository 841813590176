import React from "react";
import styled from "../../theme/styled-components";
import { BaseText } from "../Text";
import { CardPhoto, Details, HoverCard, Label, Title } from "./Card";

export type IGameCardProps = React.HTMLProps<HTMLDivElement> & {
  title: string;
  photo: string;
  label: string;
  status: string;
  pendingStatus: string;
};

const SubHeading = styled(Label)`
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 10px;
`;

const Container = styled(HoverCard)`
  display: flex;
  align-items: left;
  justify-content: none;
`;

const StatusCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 20px;
  border-radius: 6px;
  font-weight: 500;
  padding-right: 15px;
`;

const StatusColor = styled.div<any>`
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin: 10px;
  ${({ status, theme }) => {
    if (status === "pending") {
      return { backgroundColor: "orange" };
    } else if (status === "rejected") {
      return { backgroundColor: "red" };
    } else if (status === "ready-for-release") {
      return { backgroundColor: theme.colors.darkBlue };
    } else {
      return { backgroundColor: theme.colors.green };
    }
  }};
`;

const StatusText = styled(BaseText)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.nearlyBlack};
`;

export function capitalise(status: string) {
  if (status === "pending") {
    return "Pending";
  } else if (status === "rejected") {
    return "Rejected";
  } else if (status === "approved") {
    return "Approved";
  } else if (status === "ready-for-release") {
    return "Ready For Release";
  }
}

export function GameCard({
  title,
  photo,
  label,
  status,
  className,
  onClick,
}: IGameCardProps) {
  return (
    <Container className={className} onClick={onClick}>
      <Details>
        <Title>{title}</Title>
        <StatusCard>
          <StatusColor status={status} />
          <StatusText>{capitalise(status)}</StatusText>
        </StatusCard>
      </Details>
      <CardPhoto src={photo} />
    </Container>
  );
}
