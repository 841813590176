import React, { SyntheticEvent, useState } from "react";
import { MdCancel, MdClose } from "react-icons/md";
import {
  KeyboardEventHandler,
  Props as SelectProps,
  Styles,
} from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import styled from "../../theme/styled-components";
import { theme } from "../../theme/theme";
import { BaseText } from "../Text";
import {
  Container,
  Label,
  customStyles as baseCustomStyles,
  defaultTextStyle,
} from "./SelectInput";

export interface OptionType {
  label: string;
  value: string;
}

const customStyles: Styles = {
  ...baseCustomStyles,
  container: (provided) => {
    return { ...provided, width: "100%", padding: 0 };
  },
  control: (provided, state) => {
    const hasValues = (state.getValue() as OptionType[]).length > 0;
    return {
      ...provided,
      display: "flex",
      alignItems: "center",
      minHeight: "3.5rem",
      borderStyled: "solid",
      borderRadius: "12px",
      borderWidth: "2px",
      borderColor: theme.colors.offWhite,
      paddingLeft: hasValues ? "0.5rem" : "1rem",
      paddingRight: "1rem",
      paddingTop: "calc(1.3rem / 2)",
      boxSizing: "border-box",
      boxShadow: "none",
      backgroundColor: state.isDisabled
        ? "rgba(239, 239, 239, 0.3)"
        : provided.backgroundColor,

      "&:hover": {},
    };
  },
  input: (provided) => {
    return {
      ...provided,
      ...defaultTextStyle,
      maxWidth: "200px",
      marginBottom: "calc(1.3rem / 2)",
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      ...defaultTextStyle,
      top: "calc(1rem)",
    };
  },
  clearIndicator: (provided) => {
    return { ...provided, marginBottom: "calc(1.3rem / 2)" };
  },
};

export interface ICreateableSelectInputProps extends SelectProps {
  label: string;
  onUpdateValue?: (value: OptionType[]) => void;
}

// https://react-select.com/creatable
export function CreateableSelect(props: ICreateableSelectInputProps) {
  //
  const { label, onUpdateValue, ...rest } = props;

  const [inputValue, setInputValue] = useState("");

  const handleOptionCreation: KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      onUpdateValue &&
        onUpdateValue([
          ...(rest.value as OptionType[]),
          { label: inputValue, value: inputValue },
        ]);
      setInputValue("");
    }
  };

  const handleChange = (value: any) => {
    // check just for deletion
    const currentValue = rest.value as OptionType[];
    if (value !== null) {
      if (value.length < currentValue.length) {
        onUpdateValue && onUpdateValue(value);
      }
    } else {
      onUpdateValue && onUpdateValue([]);
    }
  };

  return (
    <Container>
      <Label>{props.label}</Label>
      <CreatableSelect
        {...rest}
        components={{
          MultiValue: CustomMultiValue,
          DropdownIndicator: () => null,
        }}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={(input) => setInputValue(input)}
        styles={{ ...customStyles }}
        onKeyDown={handleOptionCreation}
        openMenuOnClick={false}
        openMenuOnFocus={false}
        formatCreateLabel={(userInput) => `Add "${userInput}"`}
      />
    </Container>
  );
}

const MultiValueContainer = styled.div`
  height: 2.2rem;
  background-color: ${theme.colors.offWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: calc(1.3rem / 2);
`;

const MultiValueLabel = styled(BaseText)`
  font-size: 1rem;
  font-weight: 400;
  color: ${theme.colors.nearlyBlack};
`;

const MultiValueRemove = styled.div`
  margin-left: 5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
    background-color: ${theme.colors.paleBlue};
  }
  transition: all 0.3s;
`;

const MultiValueRemoveIcon = styled.div`
  height: 15px;
  width: 15px;
  color: ${theme.colors.nearlyBlack};
  ${MultiValueRemove}:hover & {
    color: ${theme.colors.grey};
  }
`;

function CustomMultiValue(props: any) {
  const {
    data: { value, label },
    removeProps: { onClick },
  } = props;
  return (
    <MultiValueContainer>
      <MultiValueLabel>{label}</MultiValueLabel>
      <MultiValueRemove onClick={onClick}>
        <MultiValueRemoveIcon as={MdClose} />
      </MultiValueRemove>
    </MultiValueContainer>
  );
}
