import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import styled, { css } from "../../../theme/styled-components";
import { IconType } from "react-icons";
import { BaseText } from "../../../components/Text";

interface IContainerProps extends React.HTMLProps<HTMLDivElement> {
  isCurrent: boolean;
}

const Container = styled.div<IContainerProps>`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 1.5rem;
  ${({ theme, isCurrent }) => {
    if (isCurrent) {
      return { backgroundColor: theme.colors.washedOutBlue };
    }
  }}
  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }
  transition: all 0.3s;
`;

interface INavIconProps extends React.HTMLProps<HTMLDivElement> {
  isCurrent: boolean;
}

const NavIcon = styled.div<INavIconProps>`
  font-size: 1.5rem;
  margin-left: 15px;
  margin-right: 15px;
  color: ${({ theme, isCurrent }) => {
    if (isCurrent) {
      return theme.colors.vibrantBlue;
    } else {
      return theme.colors.grey;
    }
  }};
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ILabelProps extends React.HTMLProps<HTMLHeadingElement> {
  isCurrent: boolean;
}

const Label = styled(BaseText)<ILabelProps>`
  color: ${({ theme, isCurrent }) => {
    if (isCurrent) {
      return theme.colors.darkBlue;
    } else {
      return theme.colors.grey;
    }
  }};
  font-weight: 500;
  transition: all 0.3s;
`;

interface INavButtonProps {
  icon: IconType;
  label: string;
  route?: string;
}

export function NavButton(props: INavButtonProps) {
  // Destructure
  const { route = "", icon: Icon, label } = props;

  // Get some info of the route from hooks
  const history = useHistory();
  let match = useRouteMatch();

  // Get the full route name and check if we are on that current route
  const topLevelRoute = `${match.url}${route}`;
  const isCurrentRoute =
    history.location.pathname.split("/")[2] === route.split("/")[1];

  return (
    <Container
      onClick={() => history.push(topLevelRoute)}
      isCurrent={isCurrentRoute}
    >
      <NavIcon isCurrent={isCurrentRoute}>
        <Icon />
      </NavIcon>
      <Label isCurrent={isCurrentRoute}>{label}</Label>
    </Container>
  );
}
