import React, { useEffect, useState } from "react";
import styled from "../../theme/styled-components";
import background from "../../assets/background.svg";
import { ConfirmationButton } from "../../components/Buttons/ConfirmationButton";
import { Card } from "../../components/Cards/Card";
import { BaseText, BaseTitle } from "../../components/Text";
import { useHistory } from "react-router";
import { Formik } from "formik";
import * as Yup from "yup";
import firebase from "firebase";
import { FormikInput } from "../../components/Formik/FormikInput";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  padding-top: 6%;
  padding-left: 8%;
  padding-right: 4%;
  box-sizing: border-box;
  min-height: 100vh;
  @media (max-width: 768px) {
    align-items: center;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 4%;
    padding-top: 0;
  }
`;

const Greeting = styled(BaseTitle)`
  width: 100%;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 2rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

const Body = styled.div`
  width: 100%;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex: 1;
  }
  min-height: 45vh;
`;

const LoginCard = styled(Card)`
  width: 500px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface IConfirmButtonProps extends React.HTMLProps<HTMLButtonElement> {
  mode: "login" | "forgot-password";
}

const ConfirmButton = styled(ConfirmationButton)<IConfirmButtonProps>`
  margin-top: ${({ mode }) => (mode === "login" ? "3rem" : "0rem")};
  margin-bottom: 2rem;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.lightGrey};
    &:hover {
      opacity: 1;
    }
  }
  transition: all 0.3s;
`;

const Footer = styled(BaseText)`
  margin-bottom: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const isDev = process.env.NODE_ENV === "development";

const EmailValidation = Yup.string()
  .email("Enter a valid email address")
  .required("Enter an email address");

const PasswordValidation = Yup.string()
  .min(6, "Password is too short")
  .max(20, "Password is too long")
  .required("Enter a Password");

const LoginSchema = Yup.object().shape({
  email: EmailValidation,
  password: PasswordValidation,
});

const PasswordResetSchema = Yup.object({
  email: EmailValidation,
});

type FormValues = {
  email: string;
  password?: string;
};

const initialLoginFormValues: FormValues = {
  email: isDev ? "dev@memorylanegames.com" : "",
  password: isDev ? "Thomas1234#" : "",
};

const initialPasswordResetFormValues: FormValues = {
  email: "",
};

export function Login() {
  //
  const history = useHistory();

  const [mode, setMode] = useState<"login" | "forgot-password">("login");

  useEffect(() => {
    // Check the auth state for firebase - if there is a session then
    // navigate to the homescreen
    const user = firebase.auth().currentUser;
    if (user) {
      history.push("/dashboard");
    }
  }, [history]);

  const onSubmit = async (values: FormValues) => {
    const { email, password } = values;
    // Disable UI to ensure no resubmission
    if (mode === "login" && password) {
      // Try and login and proceed to dashbaord, else if theres an error
      // alert the user what went wrong and reenable the login form
      try {
        await firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        await firebase.auth().signInWithEmailAndPassword(email, password);
        history.push("/dashboard");
      } catch (error) {
        alert(error.message);
      }
    } else {
      await firebase.auth().sendPasswordResetEmail(values.email);
      alert("Password reset email sent!");
      setMode("login");
    }
  };

  const onClickFooter = () => {
    if (mode === "login") {
      setMode("forgot-password");
    } else {
      setMode("login");
    }
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Greeting>
        Welcome to
        <br />
        Memory Lane Games
      </Greeting>
      <Body>
        <LoginCard>
          <Formik
            initialValues={
              mode === "login"
                ? initialLoginFormValues
                : initialPasswordResetFormValues
            }
            validationSchema={
              mode === "login" ? LoginSchema : PasswordResetSchema
            }
            onSubmit={onSubmit}
          >
            {(formik) => (
              <>
                <FormikInput
                  label="Email"
                  name="email"
                  placeholder="Enter your email..."
                  bottomMargin
                />
                {mode === "login" && (
                  <FormikInput
                    label="Password"
                    name="password"
                    placeholder="Enter your password..."
                    type="password"
                  />
                )}
                <ConfirmButton
                  mode={mode}
                  type="submit"
                  disabled={formik.isSubmitting}
                  onClick={() => formik.handleSubmit()}
                >
                  {mode === "login" ? "Login" : "Reset password"}
                </ConfirmButton>
              </>
            )}
          </Formik>
          <Footer onClick={onClickFooter}>
            {mode === "login" ? "Forgot your password?" : "Back to login"}
          </Footer>
        </LoginCard>
      </Body>
    </Container>
  );
}
