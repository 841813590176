import * as React from "react";
import styled from "styled-components";
import { BooleanParam, useQueryParam } from "use-query-params";
import { TitleCard } from "../../../../../../../components/Cards/TitleCard";
import { FormikCreatableSelect } from "../../../../../../../components/Formik/FormikCreatableSelect";
import { FormikSelectInput } from "../../../../../../../components/Formik/FormikSelectInput";
import ISO6391 from "iso-639-1";
import { LocationInput } from "./LocationInput";
import { useRecoilState } from "recoil";
import { Roles } from "@memorylanegames/types";
import { currentUserState } from "../../../../../../../GlobalStore";

const Card = styled(TitleCard)`
  grid-area: "general-card";
  & > :not(:last-child):not(:first-child) {
    margin-bottom: 2rem;
  }
`;

const languageOptions = ISO6391.getAllCodes().map((code) => ({
  value: code,
  label: ISO6391.getName(code),
}));

const statusOptions = [
  { value: "approved", label: "Approved" },
  { value: "ready-for-release", label: "Ready For Release" },
  { value: "rejected", label: "Rejected" },
  { value: "pending", label: "Pending" },
];

const paidOptions = [
  { value: "free", label: "Free Content" },
  { value: "paid", label: "Paid Content" },
];

const allowedGameReviewers: Roles[] = ["super-admin", "admin", "reviewer"];

export const General = () => {
  //
  const [isEditing] = useQueryParam("isEditing", BooleanParam);

  const [currentUser] = useRecoilState(currentUserState);

  return (
    <Card title="General">
      <FormikCreatableSelect
        name="keywords"
        isDisabled={!isEditing}
        label="Keywords"
        placeholder="Type to add keywords..."
      />
      <FormikSelectInput
        name="language"
        isDisabled={!isEditing}
        label="Language"
        placeholder="Type to select a language..."
        options={languageOptions}
      />
      <LocationInput
        name="location"
        isDisabled={!isEditing}
        label="Location"
        placeholder="Type to select a location..."
      />
      <FormikSelectInput
        name="status"
        isDisabled={
          !isEditing ||
          !(
            currentUser &&
            allowedGameReviewers.includes(currentUser.details.role)
          )
        }
        label="Status"
        placeholder="Type to select a status..."
        options={statusOptions}
      />
      <FormikSelectInput
        name="paid"
        isDisabled={!isEditing}
        label="Paid"
        placeholder="Please select a content type..."
        options={paidOptions}
      />
    </Card>
  );
};
