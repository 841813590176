import { GameType } from "@memorylanegames/types";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { hasNextPageOfGamesState, loadedGamesState } from "../../GlobalStore";
import { getAuthHeaders } from "../auth";
import _ from "lodash";
import { FiltersInterface } from "../../screens/Dashboard/Shared/SearchBar/SearchBar";
import { serverUrl } from "../constants";

interface GetPaginationParams {
  page: number;
  sortBy: string;
  filters: FiltersInterface;
  searchTerm: string;
}

export const getPaginatedGames = async ({
  page,
  sortBy,
  filters,
  searchTerm,
}: GetPaginationParams) => {
  const authHeaders = await getAuthHeaders();
  // Convert the filters interface to keys of filter types with an array of values
  // to match by e.g. --> { status: ['approved', 'pending'] }
  const formattedFilters = _.reduce(
    filters,
    (obj, filters, filterType) => {
      return {
        ...obj,
        [filterType]: _.flatMap(filters, (value, key) => (value ? key : [])),
      };
    },
    {}
  );
  const res = await fetch(
    `${serverUrl}/coreGames/getPaginatedGames?` +
      new URLSearchParams({
        page: page.toString(),
        sortBy: sortBy.toString(),
        filters: JSON.stringify(formattedFilters),
        searchTerm,
      }),
    {
      headers: {
        ...authHeaders,
      },
    }
  );
  if (!res.ok) {
    throw new Error(
      `Couldn't fetch general games, error code: ${res.status.toString()}`
    );
  }
  const body = await res.json();
  const games: GameType[] = body.games;
  const hasNextPage: boolean = body.hasNextPage;
  return { games, hasNextPage };
};

interface LoadPaginationParams {
  sortBy: string;
  filters: FiltersInterface;
  searchTerm: string;
  reset?: boolean;
}

export const useLoadPaginatedGames = () => {
  const [loadedGames, setLoadedGames] = useRecoilState(loadedGamesState);
  const [, setHasNextPage] = useRecoilState(hasNextPageOfGamesState);
  const pageIndex = Math.round(loadedGames.length / 6) + 1;
  const load = useCallback(
    async ({ reset, ...rest }: LoadPaginationParams) => {
      const { games, hasNextPage } = await getPaginatedGames({
        page: reset ? 1 : pageIndex,
        ...rest,
      });
      setLoadedGames(
        _.uniqBy([...(reset ? [] : loadedGames), ...games], "info.uid")
      );
      setHasNextPage(hasNextPage);
    },
    [loadedGames, setLoadedGames, pageIndex, setHasNextPage]
  );
  return load;
};
