import styled from "../theme/styled-components";

export const BaseTitle = styled.h1`
  font-family: "Raleway";
  font-weight: 500;
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 4rem;
`;

export const BaseText = styled.p`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1rem;
  margin: 0;
`;

export const BaseLabel = styled(BaseText)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 0.5rem;
`;

export const BaseLink = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 0.5rem;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const PageTitle = styled(BaseTitle)`
  font-size: 2.3rem;
  margin: 0;
`;

export const PageSubTitle = styled(BaseLabel)`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
`;
