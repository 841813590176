import { useField } from "formik";
import React from "react";
import { ValueType } from "react-select";
import { OptionType } from "../Inputs/CreateableSelect";
import { ISelectInputProps, SelectInput } from "../Inputs/SelectInput";
import _ from "lodash";

interface IFormikSelectProps extends ISelectInputProps {
  name: string;
}

export const FormikSelectInput = (props: IFormikSelectProps) => {
  //
  const { name, options, ...rest } = props;

  const [field, meta, helpers] = useField(name);

  return (
    <SelectInput
      {...field}
      options={options}
      name={name}
      value={_.find(options, { value: field.value }) as OptionType}
      onChange={(c: ValueType<OptionType>) => {
        helpers.setValue((c as OptionType).value);
      }}
      {...rest}
    />
  );
};
