import React from "react";
import styled from "../../../theme/styled-components";
import { ItemCard } from "../../../components/Cards/ItemCard";
import { PageSubTitle, PageTitle } from "../../../components/Text";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState, loadedGamesState } from "../../../GlobalStore";
import { Header } from "../Shared/Header";
import { GameCard } from "../../../components/Cards/GameCard";

const GameCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  grid-template-areas: "game-card game-card";
  margin-bottom: 2rem;
`;

const ContentCard = styled(GameCard)`
  grid-area: "game-card";
`;

export function Overview() {
  //
  const history = useHistory();

  const getGreeting = () => {
    // Get the hour of the day and set the greeting appropriately
    const hour = new Date().getHours();
    let greeting = "morning";
    if (hour < 12) {
      greeting = "morning";
    } else if (hour < 17) {
      greeting = "afternoon";
    } else {
      greeting = "evening";
    }
    return greeting;
  };

  const games = useRecoilValue(loadedGamesState);

  const currentUser = useRecoilValue(currentUserState);

  return (
    <>
      <Header>
        <PageTitle>
          Good {getGreeting()}, {currentUser?.details?.firstName}
        </PageTitle>
      </Header>
      <PageSubTitle>Content Management System Overview</PageSubTitle>
      <GameCards>
        {games.slice(0, 2).map((game, index) => (
          <ContentCard
            key={game.title}
            title={game.title}
            label="2h"
            status={game.info.status}
            pendingStatus={game.info.status}
            photo={game.coverPhoto.uri as string}
            onClick={() =>
              history.push(
                `/dashboard/cms/games/${game.info.uid}?currentQuestion=0`
              )
            }
          />
        ))}
      </GameCards>
      <PageSubTitle>Analytics Overview</PageSubTitle>
    </>
  );
}
