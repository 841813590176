import React from "react";
import styled from "../../../theme/styled-components";
import { BaseLabel, BaseText } from "../../../components/Text";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../../GlobalStore";
import { useHistory } from "react-router";

const Container = styled.div`
  margin-top: auto;
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const ProfilePicture = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 48px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  margin-right: 15px;
  box-sizing: border-box;
`;

const Details = styled.div`
  flex: 1;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Name = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.nearlyBlack};

  margin-bottom: 0.2rem;
`;

const Role = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: 400;
  font-size: 0.8rem;
`;

export function Profile() {
  //
  const history = useHistory();

  const currentUser = useRecoilValue(currentUserState);

  if (!currentUser) {
    return null;
  }

  const {
    details: { firstName, role, profilePicture },
  } = currentUser;

  return (
    <Container onClick={() => history.push("/dashboard/profile")}>
      <ProfilePicture src={profilePicture} />
      <Details>
        <Name>{firstName}</Name>
        <Role>{role}</Role>
      </Details>
    </Container>
  );
}
