import React, { PropsWithChildren, useState } from "react";
import styled from "../../../../theme/styled-components";
import { makeServiceUrl } from "../../../../utils/completion-utils";
import { defaultButtonCss } from "../../../../components/Buttons/Button";
import { FaMagic, FaSpinner } from "react-icons/fa";

interface AnswerAutocompleteButtonProps {
  answer: string;
  question: string;
  title: string;

  difficulty?: "easy" | "hard";

  onAnswers: (answers: Array<string>) => void;
  onError?: (err: Error) => void;
}

interface CompletionServiceAnswer {
  answer: string;
}

const ButtonInner = styled.button`
  ${defaultButtonCss}

  font-size: 1rem;
  margin: 0;
  margin-right: 0.5rem;

  &:disabled {
    opacity: 0.5;
  }
`;

export function AnswerAutocompleteButton({ answer, question, title, difficulty, onAnswers, onError, children }: PropsWithChildren<AnswerAutocompleteButtonProps>) {
  const [ loading, setLoading ] = useState(false);

  const onClick = async function(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(makeServiceUrl("completion", "incorrect-answers"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answer, question, title
        }),
      });

      const result = await response.json();
      const { answers }: { answers: Array<CompletionServiceAnswer> } = result;

      let sliced = answers.slice(0, 3);

      if (difficulty === "hard") {
        sliced = answers.slice(-3);
      }

      onAnswers(sliced.map(s => s.answer));
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }

    setLoading(false);
  };

  const iconStyle = { marginRight: '0.5rem' };

  return (
    <ButtonInner tabIndex={-1} onClick={onClick} disabled={loading}>
      { loading ? (
        <FaSpinner style={iconStyle} />
      ) : (
        <FaMagic style={iconStyle} />
      ) }

      { children }
    </ButtonInner>
  );
}
