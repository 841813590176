import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import styled from "../../../../theme/styled-components";
import { BaseLabel, BaseText } from "../../../../components/Text";
import { DropDownButton } from "./DropDownButton";
import { SortByInterface } from "./SearchBar";
import _ from "lodash";

const StyledDropDown = styled(DropDownButton)`
  margin-left: 20px;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px;
  box-sizing: border-box;
`;

const Item = styled.div`
  width: 200px;
  height: 50px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.offWhite};
  }
  transition: all 0.3s;
`;

const Label = styled(BaseLabel)<any>`
  margin: 0;
  color: ${({ theme, isSelected }) => {
    if (isSelected) {
      return theme.colors.vibrantBlue;
    }
  }};
  transition: all 0.3s;
`;

const SortByLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.lightGrey};
  font-weight: 400;
  margin-left: 10px;
`;

export interface ISortByProps {
  sortBys: SortByInterface[];
  sortBy: string;
  onUpdateSortBy: (sortBy: string) => void;
}

export function SortBy(props: ISortByProps) {
  //
  const { sortBys, sortBy, onUpdateSortBy } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  // When the user makes their selection close the menu
  useEffect(() => {
    setMenuOpen(false);
  }, [sortBy]);

  return (
    <StyledDropDown
      icon={BiChevronDown}
      label={
        <>
          Sort by:
          <SortByLabel>{_.find(sortBys, { id: sortBy })?.label}</SortByLabel>
        </>
      }
      menuOpen={menuOpen}
      onClick={() => setMenuOpen(!menuOpen)}
      onCloseMenu={() => setMenuOpen(false)}
      menu={
        <Menu>
          {sortBys.map((metric) => (
            <Item key={metric.id} onClick={() => onUpdateSortBy(metric.id)}>
              <Label isSelected={sortBy === metric.id}>{metric.label}</Label>
            </Item>
          ))}
        </Menu>
      }
    />
  );
}
