import React from "react";
import { IconType } from "react-icons/lib";
import { BiArrowBack } from "react-icons/bi";
import styled from "../../theme/styled-components";
import { BaseLabel } from "../Text";

const Container = styled.div`
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.9;
  }
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.grey};
  margin-right: 15px;
`;

const Text = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;

interface IBackButtonProps extends React.HTMLProps<HTMLDivElement> {}

export function BackButton(props: IBackButtonProps) {
  //
  const { onClick } = props;

  return (
    <Container onClick={onClick}>
      <Icon as={BiArrowBack} />
      <Text>Back</Text>
    </Container>
  );
}
