import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Prompt } from "react-router";
import { BooleanParam, useQueryParam } from "use-query-params";
import { Fields } from "../form/schemas";

export const PreventExit = () => {
  //
  const [isEditing] = useQueryParam("isEditing", BooleanParam);

  const { isSubmitting } = useFormikContext<Fields>();

  useEffect(() => {
    if (!!isEditing) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [isEditing]);

  return (
    <Prompt
      when={!!isEditing}
      message={(location, action) => {
        return location.pathname.startsWith("/dashboard/cms/games") ||
          isSubmitting
          ? true
          : "Are you sure you want to exit? All progress will be lost.";
      }}
    />
  );
};
