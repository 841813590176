import React from "react";
import styled, { css } from "../../theme/styled-components";
import { BaseLabel, BaseText } from "../Text";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Label = styled(BaseLabel)``;

export const Error = styled(BaseText)`
  color: ${({ theme }) => theme.colors.errorRed};
  margin-top: 0.5rem;
`;

export const defaultInputCSS = css<any>`
  height: 3.5rem;
  width: 100%;
  border-radius: 12px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.offWhite};
  outline: none;
  padding-left: 1rem;
  box-sizing: border-box;
  font-family: "Roboto";
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.nearlyBlack};
`;

interface IStyledInputProps extends React.HTMLProps<HTMLInputElement> {
  error?: string | boolean;
}

const StyledInput = styled.input<IStyledInputProps>`
  ${defaultInputCSS}
`;

export interface IInputProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  bottomMargin?: boolean;
  error?: any;
}

export function Input(props: IInputProps) {
  //
  const { label, className, bottomMargin, ...inputProps } = props;

  return (
    <Container className={className}>
      <Label>{label}</Label>
      <StyledInput {...inputProps} id="input" />
    </Container>
  );
}
