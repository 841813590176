import React from "react";
import { HoverCard, Details, Title, Label, CardPhoto } from "./Card";

export type IUserCardProps = React.HTMLProps<HTMLDivElement> & {
  title: string;
  photo: string;
  label: string;
};

export function UserCard({
  title,
  photo,
  label,
  className,
  onClick,
}: IUserCardProps) {
  return (
    <HoverCard className={className} onClick={onClick}>
      <Details>
        <Title>{title}</Title>
        <Label>{label}</Label>
      </Details>
      <CardPhoto src={photo} />
    </HoverCard>
  );
}
